// Arquivo criado: 11/08/2023 às 14:56

import { type AxiosResponse } from 'axios'
import { backEnd } from './backend.util'

export const urlFile = async (route: string): Promise<string> => {

  const response: AxiosResponse = await backEnd(
    'GET',
    route,
    undefined,
    'application/json',
    'blob'
  ) as any

  const blob = new Blob([response.data], { type: response.headers['content-type'] })
  const url = window.URL.createObjectURL(blob)

  return url
}
