// Arquivo criado: 01/09/2023 às 16:33
import React from 'react'

import * as S from './styles'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState } from '../../redux/store'
import CircularProgress from '@mui/material/CircularProgress'
import dayjs from 'dayjs'
import { theme } from '../../config/theme'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import { backEnd } from '../../utils/backend.util'
import { useNavigate } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

interface Labels {
  label: string
    id: number
    count: number
}

interface EventDefault {
  id: number
  label: string
  default: boolean
  initialDate: Date
  finalDate: Date
  subscription: boolean
  assessment: boolean
  assessmentFinalDate: Date
  assessmentInitialDate: Date
  amountWorks: number
  labels: Labels[]
}

interface IEvents {
  label: string | undefined
  id: number | undefined
}

export const Dashboard = (): JSX.Element => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const event = useSelector((state: RootState) => state.event.data)
  const [loading, setLoading] = React.useState(false)
  const [isSubmission, setIsSubmission] = React.useState(false)
  const [isEvaluation, setIsEvaluation] = React.useState(false)
  // const [eventDefaultId, setEventDefaultId] = React.useState(0)
  const [eventDefault, setEventDefault] = React.useState<EventDefault | null>(null)

  const [events, setEvents] = React.useState<IEvents[]>([])
  const [eventId, setEventId] = React.useState(event?.id)

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'Coordenadoria de Submissão de trabalhos - Dashboard'

      if (!event) return
      if (!eventId) return

      setLoading(true)
      const response = await backEnd('GET', `events/${eventId}`)
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg,
          modal: {
            title: 'Ops...'
          }
        }))

        return
      }

      /* setIsEvaluation(response.data.assessment)
      setIsSubmission(response.data.subscription) */
      setEventDefault(response.data)

    }

    void getData()
  }, [dispatch, event, eventId])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      if (!eventDefault) return

      const today = new Date()

      const finalDate = eventDefault.finalDate
      const initialDate = eventDefault.initialDate
      const assessmentFinalDate = eventDefault.assessmentFinalDate
      const assessmentInitialDate = eventDefault.assessmentInitialDate

      const isEnrollment = new Date(initialDate) <= today && new Date(finalDate) >= today
      const isEvaluation = new Date(assessmentInitialDate) <= today && new Date(assessmentFinalDate) >= today

      const data = {
        subscription: isEnrollment,
        assessment: isEvaluation
      }

      setLoading(true)
      const response = await backEnd('PUT', `events/${eventDefault.id}`, data)
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg,
          modal: {
            title: 'Ops...'
          }
        }))

        return
      }
      setIsEvaluation(isEvaluation)
      setIsSubmission(isEnrollment)

    }

    void getData()
  }, [dispatch, eventDefault])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      const response = await backEnd('GET', 'events')

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg,
          modal: {
            title: 'Ops...'
          }
        }))

        return
      }

      setEvents(response.data)

    }

    void getData()
  }, [dispatch])

  /* const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#fff',
      color: theme.palette.common.black,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#3b81f766',
      '&:last-child': {
        borderTopRightRadius: 6
      },
      '&:first-of-type': {
        borderTopLeftRadius: 6
      }
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13
    }
  })) */

  /* const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
      border: 0
    }
  })) */

  const handleSelectedEvent = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const id = Number(e.target.value)
    setEventId(id)

  }

  const handleCreateEventClick = (): void => {
    navigate('/admin/eventos')
  }

  const handleCreateEvaluatorClick = (): void => {
    navigate('/admin/avaliadores')
  }

  return (
    <S.Container>
      {
        eventDefault !== null
          ? (

            <S.ScreenContainer>
              <S.RowContainer>
                <TextField style={{ textAlign: 'center' }} size='small' select variant='standard' id='event' name='event' value={eventDefault?.label} onChange={handleSelectedEvent} InputProps={{ disableUnderline: true }}>
                  <MenuItem value={eventDefault?.label} style={{ display: 'none' }} disabled>{eventDefault?.label}</MenuItem>
                  {
                    events.map((item, index) => (
                      <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                    ))
                  }
                </TextField>
              </S.RowContainer>
              <S.RowContainer>
                <p>Este evento possui <span className='countWorks'>{eventDefault.amountWorks}</span> trabalhos submetidos</p>
              </S.RowContainer>
              <S.RowContainer className='worksInfo'>
                <h2>Resumo dos Trabalhos</h2>
              </S.RowContainer>
              <S.RowContainer className='bodyContainer'>
                {
                  loading
                    ? (
                      <S.LoadingContainer>
                        <CircularProgress />
                      </S.LoadingContainer>
                    )
                    : (
                      <S.ColumnContainer width={100}>
                        <>
                          <S.TableContainer>
                            <S.RowContainer className='tableRow tableFirstRow tableData'>
                              {
                                eventDefault.labels.map((item: Labels, index) => (
                                  <S.ColumnContainer key={index} width={20} className='tableColumn'>
                                    <h5>{item.label}</h5>
                                    <p>{item.count}</p>

                                  </S.ColumnContainer>
                                ))
                              }
                            </S.RowContainer>
                          </S.TableContainer>
                          <S.RowContainer>
                            <S.ColumnContainer>
                              <p className='description'>Submissões <span className='eventStatus' style={isSubmission ? { color: theme.palette.primary.main } : { color: theme.palette.secondary.main }}>{isSubmission ? 'em andamento' : 'pausadas'}</span></p>
                              <p>Data de expiração: <span className='eventStatus'>{dayjs(eventDefault.finalDate).format('DD/MM/YYYY')}</span></p>
                            </S.ColumnContainer>
                            <S.ColumnContainer>
                              <p className='description'>Avaliações <span className='eventStatus' style={isEvaluation ? { color: theme.palette.primary.main } : { color: theme.palette.secondary.main }}>{isEvaluation ? 'em andamento' : 'pausadas'}</span></p>
                              <p>Data de expiração: <span className='eventStatus'>{dayjs(eventDefault.assessmentFinalDate).format('DD/MM/YYYY')}</span></p>
                            </S.ColumnContainer>
                          </S.RowContainer>
                          <S.RowContainer style={{ justifyContent: 'space-around' }}>
                            <Button onClick={handleCreateEventClick} className='button' variant='outlined'>Criar Novo Evento</Button>
                            <Button onClick={handleCreateEvaluatorClick} className='button' variant='outlined'>Cadastrar Novo Avaliador</Button>
                          </S.RowContainer>
                        </>
                      </S.ColumnContainer>
                    )
                }
              </S.RowContainer>
            </S.ScreenContainer>
          )
          : (
            <p>Não há evento ativo</p>
          )
      }
    </S.Container>
  )
}
