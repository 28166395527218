// Arquivo criado: 30/07/2023 às 15:02
import React from 'react'
import * as S from './styles'
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined'
import { useSelector, useDispatch } from 'react-redux'
import { type RootState } from '../../redux/store'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import { type IWork } from '../../interfaces/IWork'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import { type IAuthor } from '../../interfaces/IAuthor'
import { urlFile } from '../../utils/urlFile.util'
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

type Work = Pick<IWork, 'title' | 'code' | 'createdAt' | 'status' | 'apresentation' | 'id' | 'local'>

export const Works = (): JSX.Element => {

  const [fileInBase, setFileInBase] = React.useState<{ id: number, name: string } | null>(null)
  const [fileUrl, setFileUrl] = React.useState<string | null>(null)

  const [includedAuthors, setIncludedAuthors] = React.useState<string[]>([])
  const [title, setTitle] = React.useState<string | null>(null)
  const [code, setCode] = React.useState<string | null>(null)
  const [board, setBoard] = React.useState<string | null>(null)
  const [management, setManagement] = React.useState<string | null>(null)
  const [category, setCategory] = React.useState<string | null>(null)
  const [model, setModel] = React.useState<string | null>(null)
  const [summary, setSummary] = React.useState<string | null>(null)
  const [tagsArray, setTagsArray] = React.useState<string[]>([])

  const [open, setOpen] = React.useState(false)
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const [deletedItem, setDeleteItem] = React.useState<number | null>(null)

  const userId = useSelector((state: RootState) => state.user.perfil?.id)
  const event = useSelector((state: RootState) => state.event.data)
  const dispatch = useDispatch()

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth)
  const [finishedworks, setFinishedWorks] = React.useState<Work[]>([])
  const [inProgressWorks, setInProgressWorks] = React.useState<Work[]>([])
  const [loading, setLoading] = React.useState(false)
  const [canEdit, setCanEdit] = React.useState(false)

  React.useEffect(() => {

    if (event) {
      const lastDay = new Date(event?.finalDate)

      const checkDates = (): void => {
        const today = new Date()
        lastDay > today ? setCanEdit(true) : setCanEdit(false)
      }

      checkDates()
      const compareDates = setInterval(checkDates, 60000)
      if (!canEdit) {
        clearInterval(compareDates)
      }

    }

  }, [event, canEdit])

  React.useEffect(() => {

    document.title = 'Submissão de trabalhos - Meus Trabalhos'

    const updateScreenWidth = (): void => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', updateScreenWidth)

    return () => {
      window.removeEventListener('resize', updateScreenWidth)
    }
  }, [])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      if (!event) return
      if (!userId) return

      setLoading(true)
      const response = await backEnd('GET', `works/event/${event.id}/user/${userId}`)
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
        return
      }
      setFinishedWorks(response.data.finishedWorks)
      setInProgressWorks(response.data.inProgressWorks)
    }
    void getData()
  }, [dispatch, event, userId])

  const handleOpen = async (id: number): Promise<void> => {

    if (!userId) return

    setLoading(true)
    const response = await backEnd('GET', `works/${id}/user/${userId}`)
    const url = await urlFile(`works/${id ?? 0}/file`)
    if (url) setFileUrl(url)
    setLoading(false)

    const authors: string[] = response.data.authors.map(function (item: IAuthor) {
      return item.name
    })

    const tagsArray: string[] = response.data.tags.split(',')

    setTitle(response.data.title)
    setSummary(response.data.summary)
    setCode(response.data.code)
    setBoard(response.data.board.label)
    setManagement(response.data.management.label)
    setCategory(response.data.category.label)
    setModel(response.data.model.label)
    setIncludedAuthors(authors)
    setTagsArray(tagsArray)
    setFileInBase(response.data.file)
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      if (!event) return
      if (!userId) return

      setLoading(true)
      const response = await backEnd('GET', `works/event/${event.id}/user/${userId}`)
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
        return
      }
      setFinishedWorks(response.data.finishedWorks)
      setInProgressWorks(response.data.inProgressWorks)
    }
    void getData()
  }, [dispatch, event, userId])

  const handleCloseDeleteModal = (): void => {
    setOpenDeleteModal(false)
  }

  const willDelete = (id: number): void => {
    setDeleteItem(id)
    setOpenDeleteModal(true)
  }

  const handleDelete = async (id: number): Promise<void> => {

    if (!event) return
    if (!userId) return

    setLoading(true)
    const response = await backEnd('DELETE', `works/${id}/user/${userId}`)
    setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    setOpenDeleteModal(false)

    const data = await backEnd('GET', `works/event/${event.id}/user/${userId}`)

    if (!data.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: data.msg
      }))
    }

    setInProgressWorks(data.data.inProgressWorks)
  }

  const tableTitles = [
    {
      title: 'Código',
      width: 9
    },
    {
      title: 'Título',
      width: 20
    },
    {
      title: 'Data/Hora Submissão',
      width: 21
    },
    {
      title: 'Status',
      width: 10
    },
    {
      title: 'Local',
      width: 10
    },
    {
      title: 'Data/Hora Apresentação',
      width: 21
    },
    {
      title: '',
      width: 9
    }
  ]

  return (
    <S.Container>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <S.SummaryContainer>
          <S.RowContainer className='summaryRow title blueBorder'>
            <p className='summaryTitle'><strong>Título: </strong>{title}</p>
          </S.RowContainer>
          <S.RowContainer className='summaryRow noBreak description'>
            <p><strong>Código: </strong>{code}</p>
            <p className='secondItem'><strong>Diretoria: </strong>{board} / {management}</p>
          </S.RowContainer>
          <S.RowContainer className='summaryRow noBreak description'>
            <p><strong>Categoria: </strong>{category} / {model}</p>
          </S.RowContainer>
          <S.RowContainer className='summaryRow noBreak middleRow'>
            <p><strong className='topic'>Resumo: </strong>{summary}</p>
          </S.RowContainer>
          <S.RowContainer style={{ alignItems: 'flex-start' }} className='summaryRow middleRow'>
            <S.ColumnContainer width={60}className='authorsStep5'>
              <p className='topic'><strong>Autor{includedAuthors.length !== 1 ? 'es:' : ':'} </strong></p>
              {
                includedAuthors.map((item, index) => (
                  <S.SingleAuthor key={index}>
                    <p className='author'>{item}</p>
                  </S.SingleAuthor>
                ))
              }
            </S.ColumnContainer>
            <S.ColumnContainer width={40} className='noMarginTop summaryTagsContainer'>
              <p className='topic'><strong>Palavras-chave:</strong></p>
              <S.TagsContainer>
                {
                  tagsArray.map((item, index) => (
                    <p key={index} className='summaryInLineItem' style={{ marginRight: 5 }}>#{item.trim()}</p>))
                }
              </S.TagsContainer>
            </S.ColumnContainer>
          </S.RowContainer>
          {
            fileUrl && (
              <S.RowContainer className='summaryRow blueBorder lastRow noBreak'>
                <p>
                  <a href={fileUrl} target='_blank' rel="noreferrer">
                    <strong className='topic'>Arquivo: </strong>{fileInBase?.name}
                  </a>
                </p>
              </S.RowContainer>
            )
          }
        </S.SummaryContainer>
      </Modal>
      <div>
        <Dialog
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
        >
          <DialogTitle id="alert-dialog-title">
            {'Deseja excluir a submissão pendente?'}
          </DialogTitle>
          <DialogContent>
            <div>
              Confirma a exclusão? Esta ação não poderá ser desfeita
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={deletedItem !== null ? async () => { await handleDelete(deletedItem) } : handleCloseDeleteModal}>Excluir</Button>
            <Button onClick={handleCloseDeleteModal} autoFocus>Cancelar</Button>
          </DialogActions>
        </Dialog>
      </div>
      <S.RowContainer className='pageTitle'>
        <h2>Meus Trabalhos</h2>
      </S.RowContainer>
      {
        loading
          ? (
            <S.LoadingContainer>
              <CircularProgress />
            </S.LoadingContainer>
          )
          : (
            <>
              {
                [
                  {
                    data: finishedworks,
                    isFinished: true,
                    title: 'Trabalhos com submissão concluída'
                  },
                  {
                    data: inProgressWorks,
                    isFinished: false,
                    title: 'Trabalhos com submissão pendente'
                  }
                ].map((info, index) => (
                  <React.Fragment key={index}>
                    {
                      screenWidth > 880
                        ? (
                          <>
                            {
                              (info.isFinished || canEdit) && (
                                <>
                                  <S.RowContainer className='countSubmissions'>
                                    <h4>{info.title}</h4>
                                  </S.RowContainer>
                                  <S.RowContainer className='countSubmissions'>
                                    <p>Fo{info.data.length !== 1 ? 'ram' : 'i'} encontrada{info.data.length !== 1 ? 's' : ''} {info.data.length} submiss{info.data.length !== 1 ? 'ões' : 'ão'} de trabalho{info.data.length !== 1 ? 's' : ''}</p>
                                  </S.RowContainer>
                                  <S.FormContainer>
                                    {
                                      tableTitles.map((item, index) => (
                                        <S.ColumnContainer key={index} width={item.width} className='tableColumn'>
                                          <S.RowContainer className='tableTitle'>
                                            <h4>{item.title}</h4>
                                          </S.RowContainer>
                                          <S.ColumnContainer width={100}>
                                            {
                                              info.data.map((elem, index) => (
                                                item.title === 'Código'
                                                  ? (
                                                    <S.RowContainer key={index} className='tableRow'>
                                                      <p>{elem.code}</p>
                                                    </S.RowContainer>
                                                  )
                                                  : item.title === 'Título'
                                                    ? (
                                                      <S.RowContainer key={index} className='tableRow'>
                                                        <p>{elem.title}</p>
                                                      </S.RowContainer>
                                                    )
                                                    : item.title === 'Data/Hora Submissão'
                                                      ? (
                                                        <S.RowContainer key={index} className='tableRow'>
                                                          <p>{dayjs(elem.createdAt).format('DD/MM/YYYY - HH:mm')}h</p>
                                                        </S.RowContainer>
                                                      )
                                                      : item.title === 'Status'
                                                        ? (
                                                          <S.RowContainer key={index} className='tableRow'>
                                                            <p>{info.isFinished ? elem.status.label : 'Em andamento'}</p>
                                                          </S.RowContainer>
                                                        )
                                                        : item.title === 'Local'
                                                          ? (
                                                            <S.RowContainer key={index} className='tableRow'>
                                                              <p>{elem.local?.toUpperCase() !== 'WEB' ? elem.local : '-'}</p>
                                                            </S.RowContainer>
                                                          )
                                                          : item.title === 'Data/Hora Apresentação'
                                                            ? (
                                                              <S.RowContainer key={index} className='tableRow'>
                                                                <p>{(elem.apresentation && elem.local?.toUpperCase() !== 'WEB') ? `${dayjs(elem.apresentation).format('DD/MM/YYYY - HH:mm')}h` : ''}</p>
                                                              </S.RowContainer>
                                                            )
                                                            : item.title === ''
                                                              ? (
                                                                <S.RowContainer key={index} style={{ justifyContent: 'space-evenly' }} className='tableRow'>
                                                                  {
                                                                    info.isFinished
                                                                      ? (
                                                                        <Button className='button' onClick={async () => { await handleOpen(elem.id) }}><LibraryBooksOutlinedIcon className='icon button' /></Button>
                                                                      )
                                                                      : (
                                                                        <Button className='button' onClick={() => { willDelete(elem.id) }}><DeleteIcon className='icon button' /></Button>
                                                                      )
                                                                  }
                                                                  {
                                                                    (canEdit || (elem.status.id === 1 || elem.status.id === 4)) && (
                                                                      <Link to={`/submissao?id=${elem.id}`}><ModeOutlinedIcon className='icon' /></Link>
                                                                    )
                                                                  }
                                                                </S.RowContainer>
                                                              )
                                                              : null
                                              )
                                              )
                                            }
                                          </S.ColumnContainer>
                                        </S.ColumnContainer>
                                      ))
                                    }
                                  </S.FormContainer>
                                </>
                              )
                            }
                          </>
                        )
                        : (
                          <S.FormContainer>
                            {
                              (info.isFinished || canEdit) && (
                                <>
                                  <S.RowContainer style={{ justifyContent: 'center' }} className='countSubmissions'>
                                    <h4>{info.title}</h4>
                                  </S.RowContainer>
                                  <S.RowContainer style={{ justifyContent: 'center' }} className='countSubmissions'>
                                    <p>Fo{info.data.length !== 1 ? 'ram' : 'i'} encontrada{info.data.length !== 1 ? 's' : ''} {info.data.length} submiss{info.data.length !== 1 ? 'ões' : 'ão'} de trabalho{info.data.length !== 1 ? 's' : ''}</p>
                                  </S.RowContainer>
                                  {
                                    info.data.map((elem, index) => (
                                      <S.RowContainer key={index} className='responsiveTable'>
                                        <S.ColumnContainer width={35}>
                                          {
                                            tableTitles.map((item, index) => (
                                              <S.ColumnContainer key={index} width={100} className='tableColumn'>
                                                <S.RowContainer className='tableTitle'>
                                                  <h4>{item.title}</h4>
                                                </S.RowContainer>
                                              </S.ColumnContainer>
                                            ))
                                          }
                                        </S.ColumnContainer>
                                        <S.ColumnContainer width={65} >
                                          <S.RowContainer className='tableRow'>
                                            <p>{elem.code}</p>
                                          </S.RowContainer>
                                          <S.RowContainer className='tableRow'>
                                            <p>{elem.title}</p>
                                          </S.RowContainer>
                                          <S.RowContainer className='tableRow'>
                                            <p>{dayjs(elem.createdAt).format('DD/MM/YYYY - HH:mm')}h</p>
                                          </S.RowContainer>
                                          <S.RowContainer className='tableRow'>
                                            <p>{info.isFinished ? elem.status.label : 'Em andamento'}</p>
                                          </S.RowContainer>
                                          <S.RowContainer className='tableRow'>
                                            <p>{elem.local?.toUpperCase() !== 'WEB' ? elem.local : '-'}</p>
                                          </S.RowContainer>
                                          <S.RowContainer className='tableRow'>
                                            <p>{(elem.apresentation && elem.local?.toUpperCase() !== 'WEB') ? `${dayjs(elem.apresentation).format('DD/MM/YYYY - HH:mm')}h` : ''}</p>
                                          </S.RowContainer>
                                          <S.RowContainer key={index} style={{ justifyContent: 'space-evenly' }} className='tableRow'>
                                            {
                                              info.isFinished
                                                ? (
                                                  <Button className='button' onClick={async () => { await handleOpen(elem.id) }}><LibraryBooksOutlinedIcon className='icon button' /></Button>
                                                )
                                                : (
                                                  <Button className='button' onClick={() => { willDelete(elem.id) }}><DeleteIcon className='icon button' /></Button>
                                                )
                                            }
                                            {
                                              (canEdit && (elem.status.id === 1 || elem.status.id === 4)) && (
                                                <Link to={`/submissao?id=${elem.id}`}><ModeOutlinedIcon className='icon' /></Link>
                                              )
                                            }
                                          </S.RowContainer>
                                        </S.ColumnContainer>
                                      </S.RowContainer>
                                    ))
                                  }
                                </>
                              )}
                          </S.FormContainer>
                        )
                    }
                  </React.Fragment>
                ))
              }
            </>
          )}
    </S.Container>
  )
}
