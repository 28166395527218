// Arquivo criado: 13/07/2023 às 18:33
import React from 'react'
import * as S from './styles'
import Button from '@mui/material/Button'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import { backEnd } from '../../utils/backend.util'
import { useDispatch } from 'react-redux'
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import { CustomInput } from '../../components/CustomInput'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
// import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

interface Evaluator {
  name: string
  email: string
  id: number
}

export const CreateEvaluator = (): JSX.Element => {

  const dispatch = useDispatch()

  // const [screenWidth, setScreenWidth] = React.useState(window.innerWidth)
  const [loading, setLoading] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)
  const [openModalEvaluatorsList, setOpenModalEvaluatorsList] = React.useState(false)

  const [evaluators, setEvaluators] = React.useState<Evaluator[]>([])
  const [firstEvaluators, setFirstEvaluators] = React.useState<Evaluator[]>([])
  // const [editEvaluator, setEditEvaluator] = React.useState<Evaluator[]>([])
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [evaluatorId, setEvaluatorId] = React.useState(0)
  const [editable, setEditable] = React.useState(false)
  const [get, setGet] = React.useState(false)

  const [file, setFile] = React.useState<File | undefined>(undefined)
  const [sortedEvaluators, setSortedEvaluators] = React.useState<Evaluator[]>([])

  React.useEffect(() => {

    const getData = async (): Promise<void> => {

      setLoading(true)
      const response = await backEnd('GET', 'evaluators')
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
      }

      setEvaluators(response.data)
      setFirstEvaluators(response.data.reverse().slice(0, 5))

      if (get) {
        setGet(false)
      }
    }

    void getData()
  }, [dispatch, get])

  React.useEffect(() => {

    setSortedEvaluators(evaluators.sort(function (a, b) {
      return a.name.localeCompare(b.name, 'pt', { sensitivity: 'base' })
    }))

  }, [evaluators])

  const handleClose = (): void => {
    setName('')
    setEmail('')
    setEvaluatorId(0)
    setOpenModal(false)
  }

  const handleCloseModalEvaluatorsList = (): void => {
    setOpenModalEvaluatorsList(false)
  }

  const handleName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value)
  }

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value)
  }

  const handleEditEvaluator = (index: number): void => {
    setOpenModalEvaluatorsList(false)
    setName(sortedEvaluators[index].name)
    setEmail(sortedEvaluators[index].email)
    setEvaluatorId(sortedEvaluators[index].id)
    setEditable(true)
  }

  const handleEditFirstEvaluators = (index: number): void => {
    setOpenModalEvaluatorsList(false)
    setName(firstEvaluators[index].name)
    setEmail(firstEvaluators[index].email)
    setEvaluatorId(firstEvaluators[index].id)
    setEditable(true)
  }

  const handleCancelEditing = (): void => {
    setName('')
    setEmail('')
    setEditable(false)
  }

  const handleDelete = (index: number): void => {
    setOpenModalEvaluatorsList(false)
    setName(sortedEvaluators[index].name)
    setEmail(sortedEvaluators[index].email)
    setEvaluatorId(sortedEvaluators[index].id)
    setOpenModal(true)
  }

  const handleDeleteFirstEvaluators = (index: number): void => {
    setOpenModalEvaluatorsList(false)
    setName(firstEvaluators[index].name)
    setEmail(firstEvaluators[index].email)
    setEvaluatorId(firstEvaluators[index].id)
    setOpenModal(true)
  }

  const handleIncludingEvaluator = async (): Promise<void> => {

    if (!name) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O nome do avaliador deve ser informado',
        type: 'error'
      }))

      return
    }

    if (name.length < 3) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O nome do avaliador deve conter no mínimo 3 caracteres',
        type: 'error'
      }))

      return
    }

    if (!name.match(/^[a-zA-ZÀ-ú ]+$/)) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O nome do avaliador deve conter apenas letras',
        type: 'error'
      }))

      return
    }

    if (!email) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O e-mail do avaliador deve ser informado',
        type: 'error'
      }))

      return
    }

    if (!email.match(/^[a-z0-9._+-]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/gi)) {
      dispatch(setOpenGlobalMessageAction({
        message: 'E-mail inválido',
        type: 'error'
      }))

      return
    }

    const data = {
      email,
      name
    }

    if (editable) {
      setLoading(true)
      const response = await backEnd('PUT', `evaluators/${evaluatorId}`, data)
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
        return
      }

      dispatch(setOpenGlobalMessageAction({
        message: 'Informações do avaliador atualizado com sucesso.',
        type: 'success',
        modal: {
          title: 'Sucesso!'
        }
      }))

      setEditable(false)
      setName('')
      setEmail('')
      setEvaluatorId(0)
      setGet(true)

      return
    }

    setLoading(true)
    const response = await backEnd('POST', 'evaluators', data)
    setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Novo avaliador incluído com sucesso.',
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))

    setName('')
    setEmail('')
    setGet(true)

  }

  const confirmDelete = async (): Promise<void> => {

    setLoading(true)
    const response = await backEnd('DELETE', `evaluators/${evaluatorId}`)
    setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Cadastro do avaliador excluído com sucesso.',
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))

    setGet(true)
    handleClose()

  }

  const handleForm = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
  }

  const uploadValidation = (file: File | undefined): void => {
    if (file) {

      const isSpreadSheet1 = /(\.xlsx)$/i
      const isSpreadSheet2 = /(\.xls)$/i

      if (!isSpreadSheet1.exec(file.name) && !isSpreadSheet2.exec(file.name)) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O arquivo selecionado deve possuir extensão .xls ou .xlsx',
          type: 'error'
        }))

        return
      }

      if (file.name.length > 255) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O nome do arquivo selecionado é maior que 255 caracteres',
          type: 'error'
        }))

        return
      }

      setFile(file)
    }
  }

  const uploadFile = async (): Promise<void> => {

    if (!file) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Por favor, selecione uma planilha',
        type: 'error'
      }))

      return
    }
    const formData = new FormData()
    formData.append('file', file)

    const response = await backEnd(
      'POST',
      'evaluators/spreadsheet',
      formData,
      'multipart/form-data'
    )

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg,
        type: 'error'
      }))

      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Cadastro de avaliadores realizado com sucesso.',
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))

    setGet(true)
    setFile(undefined)
    handleClose()
  }

  return (
    <S.Container>
      <div>
        <Dialog
          open={openModal}
          onClose={handleClose}
        >
          <DialogTitle>
            {'Salvar alterações?'}
          </DialogTitle>
          <DialogContent>
            <div>
              <p style={{ color: '#000' }}>Tem certeza que deseja ecluir o cadastro do avaliador <strong>{name}</strong>?<br />
            Esta ação não poderá ser desfeita.</p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={confirmDelete}>Deletar</Button>
            <Button onClick={handleClose}>Cancelar</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={openModalEvaluatorsList}
          onClose={handleCloseModalEvaluatorsList}
          fullWidth
          maxWidth={'sm'}
        >
          <DialogTitle>
            {'Avaliadores cadastrados'}
          </DialogTitle>
          <DialogContent>
            <div>
              <TableContainer>
                <Table>
                  {/* <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Editar</TableCell>
                      <TableCell>Excluir</TableCell>
                    </TableRow>
                  </TableHead> */}
                  <TableBody>
                    {
                      sortedEvaluators.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.name}</TableCell>
                          <TableCell><Button onClick={() => { handleEditEvaluator(index) }}><ModeOutlinedIcon className='icon' /></Button></TableCell>
                          <TableCell><Button onClick={() => { handleDelete(index) }} disabled={editable}><DeleteForeverOutlinedIcon className='icon' /></Button></TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModalEvaluatorsList}>Fechar</Button>
          </DialogActions>
        </Dialog>
      </div>
      <S.FormContainer>
        {
          loading
            ? (
              <S.LoadingContainer>
                <CircularProgress />
              </S.LoadingContainer>
            )
            : (
              <>
                <S.HeaderContainer>
                  <S.TitleContainer>
                    <h2>Cadastro de Avaliadores</h2>
                  </S.TitleContainer>
                </S.HeaderContainer>
                <S.RowContainer style={{ justifyContent: 'flex-start', alignItems: 'stretch' }}>
                  <S.ColumnContainer width={45} style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                    <S.Form onSubmit={handleForm}>
                      <S.IncludeAuthorsBox>
                        <S.InputsContainer>
                          <CustomInput id='authorName' name='authorName' onChange={e => { setName(e.target.value) }} value={name} placeholder='Nome Completo *' onBlur={handleName}/>
                          <CustomInput id='authorEmail' name='authorEmail' onChange={e => { setEmail(e.target.value) }} value={email} placeholder='E-mail *' onBlur={handleEmail}/>
                          <button id='includeAuthor' onClick={handleIncludingEvaluator}>{editable ? 'Alterar dados' : 'Incluir avaliador'}</button>
                          {
                            editable && (
                              <button id='includeAuthor' onClick={handleCancelEditing}>Cancelar</button>
                            )
                          }
                        </S.InputsContainer>
                      </S.IncludeAuthorsBox>
                    </S.Form>
                    <p>ou</p>
                    {/* <Button variant='outlined'>Importar Avaliadores</Button> */}
                    <S.SelectFileBox>
                      <label htmlFor='file'>Escolher arquivo</label>
                      <input id='file' name='file' type='file' value={''} required accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' placeholder='Anexar Arquivo' onChange={(e) => { uploadValidation(e.target.files?.[0]) }}/>
                    </S.SelectFileBox>
                    {
                      (file) && (
                        <S.RowContainer style={{ justifyContent: 'center', backgroundColor: '#3b81f744', borderRadius: 8, marginBottom: 10 }}>
                          <p><strong>Arquivo selecionado:</strong> {file.name}</p>
                        </S.RowContainer>
                      )
                    }
                    {
                      (file) && (
                        <S.RowContainer style={{ justifyContent: 'center' }}>
                          <Button variant='outlined'onClick={uploadFile}>Anexar arquivo</Button>
                        </S.RowContainer>
                      )
                    }
                  </S.ColumnContainer>
                  <S.ColumnContainer className='authors' width={55}>
                    <S.ListAuthors>
                      <h3 className='first'>Últimos Avaliadores Cadastrados:</h3>
                      {
                        firstEvaluators.map((item, index) => (
                          <S.SingleAuthor key={index}>
                            <p>{item.name}</p>
                            <Button onClick={() => { handleEditFirstEvaluators(index) }}><ModeOutlinedIcon className='icon' /></Button>
                            <Button onClick={() => { handleDeleteFirstEvaluators(index) }} disabled={editable}><DeleteForeverOutlinedIcon className='icon' /></Button>
                          </S.SingleAuthor>
                        ))
                      }

                    </S.ListAuthors>
                    <Button variant='outlined' onClick={() => { setOpenModalEvaluatorsList(true) }}>Ver Lista de Avaliadores</Button>
                  </S.ColumnContainer>
                </S.RowContainer>
              </>
            )
        }
      </S.FormContainer>
    </S.Container>
  )
}
