// Arquivo criado: 13/07/2023 às 18:33
import React from 'react'
import * as S from './styles'
import Button from '@mui/material/Button'
import { CustomSelect } from '../../components/CustomSelect'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { useLocation, useNavigate } from 'react-router-dom'
import { type IBoard } from '../../interfaces/IBoard'
import { type IManagement } from '../../interfaces/IManagement'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import { backEnd } from '../../utils/backend.util'
import { useDispatch, useSelector } from 'react-redux'
import { type IWorkCategory } from '../../interfaces/IWorkCategory'
import { type IWorkModel } from '../../interfaces/IWorkModel'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import { CustomInput } from '../../components/CustomInput'
import pdfUpload from '../../assets/pdfUpload.png'
import Checkbox from '@mui/material/Checkbox'
import { type RootState } from '../../redux/store'
import { type IAuthor } from '../../interfaces/IAuthor'
import { useDropzone } from 'react-dropzone'
import { urlFile } from '../../utils/urlFile.util'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { setEventAction } from '../../redux/actions/event.action'

interface IAuthorSubmission {
  name: string
  email: string
  cellphone: string
  boardId: number
  managementId: number
  boardLabel?: string
  managementLabel?: string
  id?: string
  triade?: boolean
}

export const Submission = (): JSX.Element => {

  // modals
  const [openModal, setOpenModal] = React.useState(false)
  const [changedStep1, setChangedStep1] = React.useState(false)
  const [changedStep2, setChangedStep2] = React.useState(false)
  const [changedStep3, setChangedStep3] = React.useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const event = useSelector((state: RootState) => state.event.data)
  const count = event?._count.works
  const userId = useSelector((state: RootState) => state.user.perfil?.id)
  const userName = useSelector((state: RootState) => state.user.perfil?.fullName)

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth)
  const [workId, setWorkId] = React.useState<number | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(true)
  const [submitLoading, setSubmitLoading] = React.useState(false)

  // step1 - Dados Iniciais
  const [step1, setStep1] = React.useState(true)
  const [step1Percent, setStep1Percent] = React.useState(0)
  const [iconStep1Color, setIconStep1Color] = React.useState('gray')
  const [title, setTitle] = React.useState('')
  const [code, setCode] = React.useState<string | null>(null)
  const [briefSummary, setBriefSummary] = React.useState('')
  const [valueDiversity, setValueDiversity] = React.useState<string | null>(null)
  const [valueLeadership, setValueLeadership] = React.useState<string | null>(null)
  const [valueCosts, setValueCosts] = React.useState<string | null>(null)
  const [boards, setBoards] = React.useState<IBoard[]>([])
  const [selectedBoard, setSelectedBoard] = React.useState(0)
  const [shownBoard, setShownBoard] = React.useState('')
  const [selectedManagement, setSelectedManagement] = React.useState(0)
  const [shownManagement, setShownManagement] = React.useState('')
  const [selectedCategory, setSelectedCategory] = React.useState(0)
  const [shownCategory, setShownCategory] = React.useState('')
  const [selectedModel, setSelectedModel] = React.useState(0)
  const [shownModel, setShownModel] = React.useState('')
  const [tags, setTags] = React.useState('')
  const [managements, setManagements] = React.useState<IManagement[]>([])
  const [categories, setCategories] = React.useState<IWorkCategory[]>([])
  const [models, setModels] = React.useState<IWorkModel[]>([])
  const step1Inputs: string[] = []
  const tagsArray: string[] = tags.split(',')
  // step 2 - Autores
  const [includedAuthors, setIncludedAuthors] = React.useState<IAuthorSubmission[]>([])
  const [editingAuthors, setEditingAuthors] = React.useState<IAuthorSubmission[]>([])
  const [author, setAuthor] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [cellphone, setCellphone] = React.useState('')
  const [shownCellphone, setShownCellphone] = React.useState('')
  const [selectedAuthorBoard, setSelectedAuthorBoard] = React.useState(0)
  const [shownAuthorBoard, setShownAuthorBoard] = React.useState<string | undefined>('Diretoria')
  const [selectedAuthorManagement, setSelectedAuthorManagement] = React.useState(0)
  const [shownAuthorManagement, setShownAuthorManagement] = React.useState<string | undefined>('Gerência')
  const [editable, setEditable] = React.useState(false)
  const [step2, setStep2] = React.useState(false)
  const [step2Percent, setStep2Percent] = React.useState(0)
  const [iconStep2Color, setIconStep2Color] = React.useState('gray')
  const [triade, setTriade] = React.useState<boolean | null>(null)
  // step3
  const [step3, setStep3] = React.useState(false)
  const [step3Percent, setStep3Percent] = React.useState(0)
  const [iconStep3Color, setIconStep3Color] = React.useState('gray')
  const [file, setFile] = React.useState<File>()
  const [fileInBase, setFileInBase] = React.useState<{ id: number, name: string } | null>(null)
  const [fileUrl, setFileUrl] = React.useState<string | null>(null)
  const [uploadFileSize, setUploadFileSize] = React.useState(0)
  const [maxFileSize, setMaxFileSize] = React.useState(0)
  const [showAppendFileButton, setShowAppendFileButton] = React.useState(false)
  // step4
  const [step4, setStep4] = React.useState(false)
  // step5
  const [checked, setChecked] = React.useState(false)
  const [step5, setStep5] = React.useState(false)
  const [alreadySent, setAlreadySent] = React.useState(false)
  // step6
  const [step6, setStep6] = React.useState(false)

  const [status, setStatus] = React.useState(4)

  React.useEffect(() => {
    const getData = async (): Promise<void> => {
      const resBoards = await backEnd('GET', 'boards')
      const resCategories = await backEnd('GET', 'works/categories')

      document.title = 'Submissão de trabalhos'

      if (!resBoards.ok || !resCategories.ok) {

        const message = resBoards.ok ? resCategories.msg : resBoards.msg

        dispatch(setOpenGlobalMessageAction({ message }))
        return
      }

      setBoards(resBoards.data)
      setCategories(resCategories.data)
      setManagements([])
    }

    void getData()
  }, [dispatch])

  React.useEffect(() => {
    const getFileSize = async (): Promise<void> => {

      const resFileSize = await backEnd('GET', 'addons/limit_size_file_works')

      if (!resFileSize.ok) {

        dispatch(setOpenGlobalMessageAction({ message: resFileSize.msg }))
        return
      }

      setMaxFileSize(Number(resFileSize.data))
    }

    void getFileSize()
  }, [dispatch])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      const params = new URLSearchParams(search)
      const workId = params.get('id')

      if (!workId) setStatus(0)

      if (!event || !userId || !workId || !Number.isInteger(parseInt(workId))) return

      setLoading(true)
      const resWork = await backEnd('GET', `works/${workId}/user/${userId}`)
      setLoading(false)

      if (!resWork.ok) {
        dispatch(setOpenGlobalMessageAction({ message: resWork.msg }))
        return
      }

      if (resWork.data) {
        setWorkId(resWork.data.id)
        setStatus(resWork.data.status.id)

        // step1
        let percent = 0
        if (resWork.data.title !== null) {
          setTitle(resWork.data.title)
          percent += 4
        }
        if (resWork.data.summary !== null) {
          setBriefSummary(resWork.data.summary)
          percent += 4
        }
        if (resWork.data.board !== null) {
          setSelectedBoard(resWork.data.board.id)
          setShownBoard(resWork.data.board.label)
          percent += 4
        }
        if (resWork.data.management !== null) {
          setSelectedManagement(resWork.data.management.id)
          setShownManagement(resWork.data.management.label)
          percent += 4
        }
        if (resWork.data.category !== null) {
          setSelectedCategory(resWork.data.category.id)
          setShownCategory(resWork.data.category.label)
          percent += 4
        }
        if (resWork.data.model !== null) {
          setSelectedModel(resWork.data.model.id)
          setShownModel(resWork.data.model.label)
          percent += 4
        }
        if (resWork.data.tags !== null) {
          setTags(resWork.data.tags)
          percent += 4
        }
        if (resWork.data.diversePopulations !== null) {
          setValueDiversity(resWork.data.diversePopulations ? 'yes' : 'no')
          percent += 4
        }
        if (resWork.data.nurse !== null) {
          setValueLeadership(resWork.data.nurse ? 'yes' : 'no')
          percent += 4
        }
        if (resWork.data.costAnalysis !== null) {
          setValueCosts(resWork.data.costAnalysis ? 'yes' : 'no')
          percent += 4
        }
        if (resWork.data.code !== null) {
          setCode(resWork.data.code)
        }

        setStep1Percent(percent)
        percent > 0 && percent < 40 ? setIconStep1Color('orange') : percent === 40 ? setIconStep1Color('green') : setIconStep1Color('gray')
        // step2
        let percent2 = 0
        const authorsInRes: IAuthor[] | null = resWork.data.authors
        if (authorsInRes && authorsInRes.length > 0) {
          percent2 += 15
          setIncludedAuthors(authorsInRes.map(author => ({
            boardId: author.board.id,
            cellphone: author.cellphone,
            email: author.email,
            managementId: author.management.id,
            name: author.name,
            boardLabel: author.board.label,
            managementLabel: author.management.label,
            id: author.email,
            triade: author.triade
          })))

          const hasTriade: boolean[] = []
          const hasFalseTriade: boolean[] = []
          authorsInRes.map(author => {
            if (author.triade !== null && author.triade) {
              hasTriade.push(author.triade)
            }
            return hasTriade
          })

          authorsInRes.map(author => {
            if (author.triade !== null && !author.triade) {
              hasFalseTriade.push(author.triade)
            }
            return hasFalseTriade
          })

          if (hasTriade.length >= 1) {
            setTriade(true)
            percent2 += 15
          }

          if (hasFalseTriade.length >= 1 && hasTriade.length < 1) {
            setTriade(false)
            percent2 += 15
          }

          setStep2Percent(percent2)
          percent2 > 0 && percent2 < 30 ? setIconStep2Color('orange') : percent2 === 30 ? setIconStep2Color('green') : setIconStep2Color('gray')

        }

        if (resWork.data.file) {
          setFileInBase(resWork.data.file)
          setStep3Percent(30)
          setIconStep3Color('green')
        }

        setChecked(resWork.data.confirmationSubmit)
        if (resWork.data.confirmationSubmit) setAlreadySent(true)

      }

    }

    void getData()
  }, [dispatch, event, search, userId])

  React.useEffect(() => {

    if (event) {
      const lastDay = new Date(event?.finalDate)

      const checkDates = (): void => {
        const today = new Date()
        if (lastDay > today) {
          setIsDisabled(false)
        } else {
          setIsDisabled(true)
          if (status !== 4) {
            dispatch(setOpenGlobalMessageAction({
              message: 'O período de submissão de trabalhos terminou',
              type: 'error'
            }))
          }
        }
      }
      checkDates()
      const compareDates = setInterval(checkDates, 60000)
      if (isDisabled) {
        clearInterval(compareDates)
      }
    }

  }, [event, dispatch, isDisabled, status])

  React.useEffect(() => {
    const getUrl = async (): Promise<void> => {
      if (step5 && workId && fileUrl === null) {
        const url = await urlFile(`works/${workId ?? 0}/file`)

        if (url) setFileUrl(url)
      }
    }
    void getUrl()
  }, [fileUrl, step5, workId])

  React.useEffect(() => {

    const updateScreenWidth = (): void => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', updateScreenWidth)

    return () => {
      window.removeEventListener('resize', updateScreenWidth)
    }
  }, [])

  React.useEffect(() => {

    const setVoid = (): void => {
      setAuthor('')
      setEmail('')
      setCellphone('')
      setSelectedAuthorBoard(0)
      setShownAuthorBoard('')
      setSelectedAuthorManagement(0)
      setShownAuthorManagement('')
    }
    const includeAuthorButton = document.getElementById('includeAuthor')
    includeAuthorButton?.addEventListener('click', setVoid)

  }, [])

  const handleClose = (): void => {
    setOpenModal(false)
  }

  const handleModal = (): void => {
    setOpenModal(true)
  }

  // step1

  const handleChangeDiversity = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValueDiversity(event.target.value)
    setChangedStep1(true)
  }

  const handleChangeLeadership = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValueLeadership(event.target.value)
    setChangedStep1(true)
  }

  const handleChangeCosts = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValueCosts(event.target.value)
    setChangedStep1(true)
  }

  const handleTitle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(event.target.value)
    setChangedStep1(true)
  }

  const handleTags = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTags(event.target.value)
    setChangedStep1(true)
  }

  const handleBriefSummary = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setBriefSummary(event.target.value)
    setChangedStep1(true)
  }

  const handleSelectBoard = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const board = boards.find(item => item.id === id)
    setSelectedBoard(id)
    setShownManagement('Gerência')
    setSelectedManagement(0)

    if (!board) return

    const response = await backEnd('GET', `managements/board/${board.id}`)
    setShownBoard(board.label)
    setChangedStep1(true)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    setManagements(response.data)
  }

  const handleSelectManagement = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const id = Number(e.target.value)
    const management = managements.find(item => item.id === id)
    setSelectedManagement(id)
    if (!management) return
    setShownManagement(management.label)
    setChangedStep1(true)
  }

  const handleSelectCategory = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const category = categories.find(item => item.id === id)
    setSelectedCategory(id)
    setShownModel('Modelo/Ferramenta')
    setSelectedModel(0)

    if (!category) return

    const response = await backEnd('GET', `works/models/category/${category.id}`)
    setShownCategory(category.label)
    setChangedStep1(true)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    setModels(response.data)
  }

  const handleSelectModel = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const id = Number(e.target.value)
    setSelectedModel(id)
    const model = models.find(item => item.id === id)
    if (!model) return
    setShownModel(model.label)
    setChangedStep1(true)
  }

  const handleSaveStep1 = async (): Promise<void> => {

    // validações
    if (!title || title === '' || title === null) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe o título do trabalho para prosseguir',
        type: 'error'
      }))

      return
    }

    if (title !== '' && title !== null) {

      if (title.length < 3) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O título deve conter no mínimo 3 caracteres',
          type: 'error'
        }))

        return
      }

      if (title.length > 255) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O título não deve conter mais que 255 caracteres',
          type: 'error'
        }))

        return
      }

      if (!title.match(/^[\p{L}0-9 ?!,.:;'-]+$/u)) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O título não deve conter alguns caracteres especiais',
          type: 'error'
        }))

        return
      }

      step1Inputs.push(title)

    }

    if (!briefSummary || briefSummary === '' || briefSummary === null) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Escreva um breve resumo do trabalho para prosseguir',
        type: 'error'
      }))

      return
    }

    if (briefSummary !== '' && briefSummary !== null) {

      if (briefSummary.length < 10) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O resumo deve conter no mínimo 10 caracteres',
          type: 'error'
        }))

        return
      }

      if (briefSummary.length > 255) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O resumo não deve conter mais que 255 caracteres',
          type: 'error'
        }))

        return
      }

      if (!briefSummary.match(/^[\p{L}0-9 ?!,.:;'+=-]+$/u)) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O resumo não deve conter alguns caracteres especiais',
          type: 'error'
        }))

        return
      }

      step1Inputs.push(briefSummary)

    }

    if (shownBoard === '' || shownBoard === 'Diretoria') {
      dispatch(setOpenGlobalMessageAction({
        message: 'Selecione a Diretoria para prosseguir',
        type: 'error'
      }))

      return
    }

    if (shownManagement === '' || shownManagement === 'Gerência') {
      dispatch(setOpenGlobalMessageAction({
        message: 'Selecione a Gerência para prosseguir',
        type: 'error'
      }))

      return
    }

    if (shownCategory === '' || shownCategory === 'Categoria') {
      dispatch(setOpenGlobalMessageAction({
        message: 'Selecione a Categoria para prosseguir',
        type: 'error'
      }))

      return
    }

    if (shownModel === '' || shownModel === 'Modelo/Ferramenta') {
      dispatch(setOpenGlobalMessageAction({
        message: 'Selecione o Modelo/Ferramenta para prosseguir',
        type: 'error'
      }))

      return
    }

    if (!tags || tags === '' || tags === null) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe pelo menos uma palavra-chave sobre o trabalho para prosseguir',
        type: 'error'
      }))

      return
    }

    if (tags !== '' && tags !== null) {

      if (tags.length < 2) {
        dispatch(setOpenGlobalMessageAction({
          message: 'Cada tag deve conter no mínimo 2 caracteres',
          type: 'error'
        }))

        return
      }

      if (!tags.match(/^[\p{L}0-9 ,'-]+$/u)) {
        dispatch(setOpenGlobalMessageAction({
          message: 'As tags não devem possuir caracteres especiais, porém devem ser separadas por vírgula',
          type: 'error'
        }))

        return
      }

      step1Inputs.push(tags)

    }

    if (valueDiversity === null || valueLeadership === null || valueCosts === null) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Responda "Sim" ou "Não" para as 3 perguntas referentes ao trabalho para prosseguir',
        type: 'error'
      }))

      return
    }

    if (valueDiversity !== null) {
      step1Inputs.push(valueDiversity)
    }

    if (valueLeadership !== null) {
      step1Inputs.push(valueLeadership)
    }

    if (valueCosts !== null) {
      step1Inputs.push(valueCosts)
    }

    step1Inputs.push(selectedBoard.toString(), selectedCategory.toString(), selectedManagement.toString(), selectedModel.toString())
    const setProgressStep1 = (): number => {
      let number = 0
      step1Inputs.map((item, index) => {
        if (item !== '' && item !== '0') {
          number += 4
        }
        return number
      })
      return number
    }

    const data = {
      title: title || null,
      summary: briefSummary || null,
      diversePopulations: valueDiversity === 'yes' ? true : valueDiversity === 'no' ? false : null,
      nurse: valueLeadership === 'yes' ? true : valueLeadership === 'no' ? false : null,
      costAnalysis: valueCosts === 'yes' ? true : valueCosts === 'no' ? false : null,
      boardId: selectedBoard > 0 ? selectedBoard : null,
      managementId: selectedManagement > 0 ? selectedManagement : null,
      categoryId: selectedCategory > 0 ? selectedCategory : null,
      modelId: selectedModel > 0 ? selectedModel : null,
      tags: tags || null
    }

    if (!event) return

    const response = await backEnd('POST', `works/event/${event.id}${workId ? `?workId=${workId}` : ''}`, data)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    setWorkId(response.data.id)
    const percent = setProgressStep1()
    setStep1Percent(percent)
    percent > 0 && percent < 40 ? setIconStep1Color('orange') : percent === 40 ? setIconStep1Color('green') : setIconStep1Color('gray')

    setChangedStep1(false)
    handleClose()

    dispatch(setOpenGlobalMessageAction({
      message: response.msg,
      type: 'success'
    }))

  }

  const handleClickStep1 = (): void => {
    handleClose()
    setStep1(false)
    setStep3(false)
    setStep4(false)
    setStep5(false)
    setStep6(false)
    setStep2(true)
  }

  const showStep1 = (): void => {
    setStep1(true)
    setStep2(false)
    setStep3(false)
    setStep4(false)
    setStep5(false)
    setStep6(false)
  }

  // step2
  const handleAuthorName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setAuthor(event.target.value)
  }

  const handleAuthorEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value)
  }

  const phoneMask = (value: string): string => {
    if (!value) return ''
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{2})(\d)/, '($1) $2')
    value = value.replace(/(\d)(\d{4})$/, '$1-$2')
    return value
  }

  const handleCellPhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const input = event.target
    input.value = phoneMask(input.value)
    setShownCellphone(input.value)
  }

  const handleCellphone = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const _cellphone = event.target.value
    const noMaskCellphone = _cellphone.replace(/\D+/g, '')
    setCellphone(noMaskCellphone)
  }

  const handleSelectAuthorBoard = async (e: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const id = Number(e.target.value)
    const board = boards.find(item => item.id === id)
    setSelectedAuthorBoard(id)
    setShownAuthorManagement('')

    if (!board) return

    const response = await backEnd('GET', `managements/board/${board.id}`)
    setShownAuthorBoard(board.label)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    setManagements(response.data)
  }

  const handleSelectAuthorTriade = (e: React.ChangeEvent<HTMLInputElement>, id: string | undefined): void => {
    const _id = id
    const _author = includedAuthors.find(item => item.id === _id)

    if (!_author) return

    _author.triade = e.target.checked
    setIncludedAuthors([...includedAuthors])
    setChangedStep2(true)
  }

  const handleTriade = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    value === 'true' ? setTriade(true) : setTriade(false)
    setChangedStep2(true)
  }

  const handleSelectAuthorManagement = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const id = Number(e.target.value)
    setSelectedAuthorManagement(id)
    const management = managements.find(item => item.id === id)
    if (!management) return
    setShownAuthorManagement(management.label)
  }

  const handleEditAuthor = (index: number): void => {
    setAuthor(includedAuthors[index].name)
    setEmail(includedAuthors[index].email)
    setCellphone(includedAuthors[index].cellphone)
    setShownCellphone(phoneMask(includedAuthors[index].cellphone))
    setSelectedAuthorBoard(includedAuthors[index].boardId)
    setShownAuthorBoard(includedAuthors[index].boardLabel)
    setSelectedAuthorManagement(includedAuthors[index].managementId)
    setShownAuthorManagement(includedAuthors[index].managementLabel)
    setEditable(true)
    setEditingAuthors(includedAuthors.filter(author =>
      author.id !== includedAuthors[index].id
    ))
  }

  const handleDeleteAuthor = (index: number): void => {
    setIncludedAuthors(
      includedAuthors.filter(author =>
        author.id !== includedAuthors[index].id
      )
    )
  }

  const handleIncludingAuthor = (): void => {

    // validações

    if (!author) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O nome do autor deve ser informado',
        type: 'error'
      }))

      return
    }

    if (author.length < 3) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O nome do autor deve conter no mínimo 3 caracteres',
        type: 'error'
      }))

      return
    }

    if (!author.match(/^[a-zA-ZÀ-ú ]+$/)) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O nome do autor deve conter apenas letras',
        type: 'error'
      }))

      return
    }

    if (!email) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O e-mail do autor deve ser informado',
        type: 'error'
      }))

      return
    }

    if (!email.match(/^[a-z0-9._+-]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/gi)) {
      dispatch(setOpenGlobalMessageAction({
        message: 'E-mail inválido',
        type: 'error'
      }))

      return
    }

    if (!cellphone) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Informe o número do celular do autor para continuar',
        type: 'error'
      }))

      return
    }

    if (cellphone.length !== 11) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O número do celular deve conter 2 dígitos para o DDD e mais 9 dígitos',
        type: 'error'
      }))
      return
    }

    if (!selectedAuthorBoard) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A Diretoria do autor deve ser informada',
        type: 'error'
      }))

      return
    }

    if (shownAuthorManagement === '') {
      dispatch(setOpenGlobalMessageAction({
        message: 'A Gerência do autor deve ser informada',
        type: 'error'
      }))

      return
    }

    if (!selectedAuthorManagement) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A Gerência do autor deve ser informada',
        type: 'error'
      }))

      return
    }

    if (editable) {

      setIncludedAuthors([
        ...editingAuthors,
        { name: author, email, cellphone, boardId: selectedAuthorBoard, managementId: selectedAuthorManagement }
      ])
      setEditable(false)
    } else {

      if (includedAuthors.length === 5) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O número máximo de autores por trabalho é 5',
          type: 'error'
        }))

        return
      }
      if (includedAuthors.length < 5) {

        setIncludedAuthors([
          ...includedAuthors,
          { name: author, email, cellphone, boardId: selectedAuthorBoard, managementId: selectedAuthorManagement, id: email, boardLabel: shownAuthorBoard, managementLabel: shownAuthorManagement }
        ])
      }
    }

    setAuthor('')
    setEmail('')
    setCellphone('')
    setShownCellphone('')
    setSelectedAuthorBoard(0)
    setShownAuthorBoard('Diretoria')
    setSelectedAuthorManagement(0)
    setShownAuthorManagement('Gerência')
    setChangedStep2(true)

  }

  const handleSaveAuthors = async (): Promise<void> => {

    if (!event) return

    if (triade === null) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Responda à pergunta sobre as Tríades para continuar',
        type: 'error'
      }))

      return
    }

    let percent2 = 0

    const filteredData = includedAuthors.map(author => ({
      boardId: author.boardId,
      cellphone: author.cellphone,
      email: author.email,
      managementId: author.managementId,
      name: author.name,
      triade: author.triade
    }))

    const data = {
      authors: filteredData
    }

    const response = await backEnd('POST', `works/event/${event.id}${workId ? `?workId=${workId}` : ''}`, data)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }
    percent2 += 15
    setWorkId(response.data.id)

    dispatch(setOpenGlobalMessageAction({
      message: response.msg,
      type: 'success'
    }))

    if (triade !== null) {
      percent2 += 15
    }

    setStep2Percent(percent2)
    percent2 > 0 && percent2 < 30 ? setIconStep2Color('orange') : percent2 === 30 ? setIconStep2Color('green') : setIconStep2Color('gray')
    setChangedStep2(false)
    handleClose()
  }

  const handleClickStep2 = (): void => {
    handleClose()
    setStep1(false)
    setStep2(false)
    setStep4(false)
    setStep5(false)
    setStep6(false)
    setStep3(true)

  }

  const showStep2 = (): void => {
    setStep1(false)
    setStep3(false)
    setStep4(false)
    setStep5(false)
    setStep6(false)
    setStep2(true)
  }

  // step3
  const uploadValidation = (file?: File | null): void => {
    if (file) {

      const fileSize = file.size

      if (fileSize > 100000000) {
        dispatch(setOpenGlobalMessageAction({
          message: `O tamanho máximo do arquivo selecionado deve ser ${maxFileSize}MB.`,
          type: 'error'
        }))

        return
      }

      const isPDF = /(\.pdf)$/i

      if (!isPDF.exec(file.name)) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O arquivo selecionado deve possuir extensão .pdf',
          type: 'error'
        }))

        return
      }

      if (file.name.length > 255) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O nome do arquivo selecionado é maior que 255 caracteres',
          type: 'error'
        }))

        return
      }
      setUploadFileSize(fileSize)
      setFile(file)
      setFileUrl(null)
      setShowAppendFileButton(true)
      setChangedStep3(true)

    }
  }

  const handleClickStep3 = async (): Promise<void> => {

    if (!file) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Por favor, selecione um arquivo PDF',
        type: 'error'
      }))

      return
    }
    const formData = new FormData()
    formData.append('file', file)

    if (!event) return

    const response = await backEnd(
      'POST',
      `works/event/${event.id}/upload${workId ? `?workId=${workId}` : ''}`,
      formData,
      'multipart/form-data'
    )

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg,
        type: 'error'
      }))

      return
    }

    setFileInBase(response.data.file)
    setWorkId(response.data.id)
    setShowAppendFileButton(false)
    setStep3Percent(30)
    setIconStep3Color('green')
    setChangedStep3(false)
    handleClose()
    if ((step1Percent + step2Percent) >= 70) {
      goToStep4()
    }
  }

  const showStep3 = (): void => {
    setStep1(false)
    setStep2(false)
    setStep4(false)
    setStep5(false)
    setStep6(false)
    setStep3(true)
  }

  const goToStep4 = (): void => {
    handleClose()
    setStep1(false)
    setStep2(false)
    setStep3(false)
    setStep4(true)
    setStep5(false)
    setStep6(false)
  }

  // step4
  const handleClickStep4 = (): void => {
    setStep1(false)
    setStep2(false)
    setStep3(false)
    setStep4(false)
    setStep6(false)
    setStep5(true)
  }

  // step5
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked)
  }

  // step6
  const handleClickStep6 = (): void => {
    setStep6(false)
    navigate('/trabalhos')
  }

  const saveAllData = async (): Promise<void> => {

    // step1

    if (!title) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O título do trabalho deve ser informado',
        type: 'error'
      }))

      return
    }

    if (!briefSummary) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O resumo do trabalho deve ser informado',
        type: 'error'
      }))

      return
    }

    if (!selectedBoard) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A Diretoria deve ser informada',
        type: 'error'
      }))

      return
    }

    if (!selectedManagement) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A Gerência deve ser informada',
        type: 'error'
      }))

      return
    }

    if (!selectedCategory) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A Categoria deve ser informada',
        type: 'error'
      }))

      return
    }

    if (!selectedModel) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O Modelo / Ferramenta deve ser informado',
        type: 'error'
      }))

      return
    }

    if (!tags) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Por favor, informe uma ou mais palavras-chave sobre o seu trabalho (separadas por vírgula)',
        type: 'error'
      }))

      return
    }

    if (!valueDiversity) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Por favor, responda "Sim" ou "Não" à pergunta sobre DIVERSIDADE na Etapa 1',
        type: 'error'
      }))

      return
    }

    if (!valueLeadership) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Por favor, responda "Sim" ou "Não" à pergunta sobre LIDERANÇA na Etapa 1',
        type: 'error'
      }))

      return
    }

    if (!valueCosts) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Por favor, responda "Sim" ou "Não" à pergunta sobre ANÁLISE DE CUSTO na Etapa 1',
        type: 'error'
      }))

      return
    }

    // step2

    if (includedAuthors.length < 1) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Por favor, informe os dados dos autores do trabalho',
        type: 'error'
      }))

      return
    }

    if (includedAuthors.length > 5) {
      dispatch(setOpenGlobalMessageAction({
        message: 'O número máximo de autores por trabalho é 5',
        type: 'error'
      }))

      return
    }

    // step3

    if (!fileInBase) {
      if (!file) {
        dispatch(setOpenGlobalMessageAction({
          message: 'Por favor, selecione um arquivo PDF',
          type: 'error'
        }))

        return
      }

      if (!uploadFileSize) {
        dispatch(setOpenGlobalMessageAction({
          message: 'Por favor, selecione um arquivo PDF',
          type: 'error'
        }))

        return
      }

      if (uploadFileSize > 100) {
        dispatch(setOpenGlobalMessageAction({
          message: `Por favor, selecione um arquivo PDF com até ${maxFileSize}MB`,
          type: 'error'
        }))

        return
      }
    }

    const data = {
      title: title || null,
      summary: briefSummary || null,
      diversePopulations: valueDiversity === 'yes',
      nurse: valueLeadership === 'yes',
      costAnalysis: valueCosts === 'yes',
      boardId: selectedBoard > 0 ? selectedBoard : null,
      managementId: selectedManagement > 0 ? selectedManagement : null,
      categoryId: selectedCategory > 0 ? selectedCategory : null,
      modelId: selectedModel > 0 ? selectedModel : null,
      tags: tags || null,
      confirmationSubmit: true,
      authors: includedAuthors,
      fileId: fileInBase?.id ?? null
    }

    if (!event) return

    setSubmitLoading(true)
    const response = await backEnd('POST', `works/event/${event.id}${workId ? `?workId=${workId}` : ''}`, data)
    setSubmitLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg,
        type: 'error'
      }))
      return
    }

    setAlreadySent(true)

    if (!workId) {
      setWorkId(response.data.id)
    }

    if (count !== undefined) {
      dispatch(setEventAction({
        ...event,
        _count: { works: count + 1 }
      }))
    }

    setStep5(false)
    setStep4(false)
    setStep3(false)
    setStep2(false)
    setStep1(false)
    setStep6(true)
  }

  // form

  const handleForm = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
  }

  const onDrop = (acceptedFiles: File[]): void => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      uploadValidation(file)
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true })

  const handleSaves = (): void => {
    if (changedStep1) {
      void handleSaveStep1()
      return
    }

    if (changedStep2) {
      void handleSaveAuthors()
      return
    }

    if (changedStep3) {
      void handleClickStep3()
    }
  }

  const handleNextSteps = (): void => {

    if (changedStep1) {
      setChangedStep1(false)
      // handleClickStep1()
      handleClose()
      return
    }

    if (changedStep2) {
      setChangedStep2(false)
      // handleClickStep2()
      handleClose()
      return
    }

    if (changedStep3) {
      setChangedStep3(false)
      handleClose()
    }
  }

  return (
    <S.Container {...getRootProps()} $isdragactive={isDragActive}>
      <div>
        <Dialog
          open={openModal}
          onClose={handleClose}
        >
          <DialogTitle id="alert-dialog-title">
            {'Salvar alterações?'}
          </DialogTitle>
          <DialogContent>
            <div>
              <p style={{ color: '#000' }}>Você fez alterações nesta etapa. Deseja salvar suas alterações antes de prosseguir para outra etapa?
            Caso siga sem salvar, as informações fornecidas serão perdidas.</p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaves} autoFocus>Salvar</Button>
            <Button onClick={handleNextSteps}>Não salvar agora</Button>
          </DialogActions>
        </Dialog>
      </div>
      {
        !step6
          ? (
            <S.FormContainer>
              {
                loading
                  ? (
                    <S.LoadingContainer>
                      <CircularProgress />
                    </S.LoadingContainer>
                  )
                  : (
                    <>
                      <S.HeaderContainer>
                        {
                          screenWidth <= 1000 && (
                            <S.TitleContainer>
                              <h2>Submissão de Trabalhos</h2>
                              {
                                step1
                                  ? (
                                    <h2>Dados Iniciais</h2>
                                  )
                                  : step2
                                    ? (
                                      <h2>Incluir Autores</h2>
                                    )
                                    : step3
                                      ? (
                                        <h2>Anexar Arquivo</h2>
                                      )
                                      : step5
                                        ? (
                                          <h2>Resumo Completo</h2>
                                        )
                                        : null
                              }
                            </S.TitleContainer>
                          )
                        }

                        <S.RowContainerForSteps className='rowFxStart'>
                          <S.StepperContainer>
                            <S.StepsContainer className='rowSpBetween'>
                              <S.Steps onClick={(changedStep2 || changedStep3) ? handleModal : showStep1}>
                                <p style={step1 ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}>Etapa 1</p>
                                <CheckCircleOutlinedIcon className='icon' style={{ color: iconStep1Color }}/>
                              </S.Steps>
                              <S.Steps onClick={(changedStep1 || changedStep3) ? handleModal : showStep2} disabled={step1Percent < 40}>
                                <p style={step2 ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}>Etapa 2</p>
                                <CheckCircleOutlinedIcon className='icon' style={{ color: iconStep2Color }}/>
                              </S.Steps>
                              <S.Steps onClick={(changedStep1 || changedStep2) ? handleModal : showStep3} disabled={step1Percent < 40 || step2Percent < 30}>
                                <p style={step3 ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}>Etapa 3</p>
                                <CheckCircleOutlinedIcon className='icon' style={{ color: iconStep3Color }}/>
                              </S.Steps>
                            </S.StepsContainer>
                            <S.RowContainer>
                              <S.Stepper>
                                <S.ShadowStepper style={{ width: `${step1Percent + step2Percent + step3Percent}%` }}/>
                              </S.Stepper>
                            </S.RowContainer>
                          </S.StepperContainer>
                        </S.RowContainerForSteps>
                        {
                          ((step1Percent + step2Percent + step3Percent) >= 100 && !step4 && !step5) && (
                            <S.ButtonHeaderContainer>
                              <Button id='continueToStep2' variant='outlined' onClick={(changedStep1 || changedStep2 || changedStep3) ? handleModal : handleClickStep4}>{ isDisabled ? 'Ver Resumo Completo' : alreadySent ? 'Ver Resumo e Salvar Alerações' : 'Finalizar Envio do Trabalho'}</Button>
                            </S.ButtonHeaderContainer>
                          )
                        }
                      </S.HeaderContainer>
                      <S.Form onSubmit={handleForm}>
                        {
                          step1
                            ? (
                              <>
                                <S.RowContainer className='rowFxStart'>
                                  <S.ColumnContainer width={60}>
                                    <TextField size='small' multiline rows={2} id='title' name='title' label='Título do trabalho' defaultValue={title} style={{ marginTop: 0 }} inputProps={{ maxLength: 255 }} onChange={handleTitle}/>
                                    <TextField size='small' multiline rows={2} id='briefSummary' name='briefSummary' label='Breve resumo' defaultValue={briefSummary} inputProps={{ maxLength: 255 }} onChange={handleBriefSummary}/>
                                    <S.RowContainer style={screenWidth >= 1000 ? { justifyContent: 'space-between', width: '98%' } : { justifyContent: 'space-between', width: '100%' }}>
                                      <S.ColumnContainer width={screenWidth >= 1000 ? 49 : 100} className='step1Inputs'>
                                        <TextField fullWidth size='small' select id='board' name='board' value={shownBoard} onChange={handleSelectBoard} label='Diretoria'>
                                          <MenuItem value={shownBoard} style={{ display: 'none' }} disabled>{shownBoard}</MenuItem>
                                          {
                                            boards.map((item, index) => (
                                              <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                                            ))
                                          }
                                        </TextField>
                                        <TextField fullWidth size='small' select id='management' name='management' value={shownManagement} disabled={managements.length === 0} onChange={handleSelectManagement} label='Gerência'>
                                          <MenuItem value={shownManagement} style={{ display: 'none' }} disabled>{shownManagement}</MenuItem>
                                          {
                                            managements.map((item, index) => (
                                              <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                                            ))
                                          }
                                        </TextField>
                                      </S.ColumnContainer>
                                      <S.ColumnContainer width={screenWidth >= 1000 ? 49 : 100} style={ screenWidth >= 1000 ? { alignItems: 'flex-end', alignSelf: 'flex-end' } : { alignItems: 'center' }} className='step1Inputs'>
                                        <TextField fullWidth size='small' select id='category' name='category' value={shownCategory} onChange={handleSelectCategory} label='Categoria'>
                                          <MenuItem value={shownCategory} style={{ display: 'none' }} disabled>{shownCategory}</MenuItem>
                                          {
                                            categories.map((item, index) => (
                                              <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                                            ))
                                          }
                                        </TextField>
                                        <TextField fullWidth size='small' select id='modelTool' name='modelTool' value={shownModel} disabled={models.length === 0} onChange={handleSelectModel} label='Modelo / Ferramenta'>
                                          <MenuItem value={shownModel} style={{ display: 'none' }} disabled>{shownModel}</MenuItem>
                                          {
                                            models.map((item, index) => (
                                              <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                                            ))
                                          }
                                        </TextField>
                                      </S.ColumnContainer>
                                    </S.RowContainer>
                                    <TextField size='small' multiline rows={1} id='tags' name='tags' label='Palavras-chave' defaultValue={tags} onChange={handleTags}/>
                                  </S.ColumnContainer>
                                  <S.RadioGroupContainer>
                                    {
                                      screenWidth > 1000 && (
                                        <S.TitleContainer>
                                          <h2>Submissão de Trabalhos</h2>
                                          <h2>Dados Iniciais</h2>
                                        </S.TitleContainer>
                                      )
                                    }
                                    <p className='first'>O trabalho contempla melhoria de processo considerando as populações diversas vulneráveis como: população negra, pessoas com deficiência, LGBTQIAPN+, mulheres, população acima de 60 anos, migrantes, refugiados ou pessoas de culturas diversas?</p>
                                    <FormControl>
                                      <RadioGroup
                                        row
                                        id='diversity'
                                        name="diversity"
                                        value={valueDiversity}
                                        onChange={handleChangeDiversity}
                                      >
                                        <FormControlLabel value="yes" control={<Radio />} label="Sim"/>
                                        <FormControlLabel value="no" control={<Radio />} label="Não"/>
                                      </RadioGroup>
                                    </FormControl>
                                    <p>Trabalho liderado por enfermeiro?</p>
                                    <FormControl>
                                      <RadioGroup
                                        row
                                        id='leadership'
                                        name="leadership"
                                        value={valueLeadership}
                                        onChange={handleChangeLeadership}
                                      >
                                        <FormControlLabel value="yes" control={<Radio />} label="Sim"/>
                                        <FormControlLabel value="no" control={<Radio />} label="Não"/>
                                      </RadioGroup>
                                    </FormControl>
                                    <p>Trabalho tem análise de custo?</p>
                                    <FormControl>
                                      <RadioGroup
                                        row
                                        id='costs'
                                        name="costs"
                                        value={valueCosts}
                                        onChange={handleChangeCosts}
                                      >
                                        <FormControlLabel value="yes" control={<Radio />} label="Sim"/>
                                        <FormControlLabel value="no" control={<Radio />} label="Não"/>
                                      </RadioGroup>
                                    </FormControl>
                                  </S.RadioGroupContainer>
                                </S.RowContainer>
                                <S.ButtonsContainer>
                                  <Button id='saveStep1' variant='outlined' onClick={handleSaveStep1}>Salvar</Button>
                                  {
                                    step1Percent === 40 && (
                                      <Button id='continueToStep2' variant='outlined' onClick={ changedStep1 ? handleModal : handleClickStep1}>Continuar para Etapa 2</Button>
                                    )
                                  }
                                </S.ButtonsContainer>
                              </>
                            )
                            : step2
                              ? (
                                <>
                                  <S.RowContainer style={{ justifyContent: 'flex-start', alignItems: 'stretch' }}>
                                    <S.ColumnContainer width={45} style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                      <S.IncludeAuthorsBox>
                                        <p>Inclua os dados dos autores, inclusive os seus se você também é autor deste trabalho:</p>
                                        <S.InputsContainer>
                                          <CustomInput id='authorName' name='authorName' onChange={e => { setAuthor(e.target.value) }} value={author} placeholder='Nome Completo *' onBlur={handleAuthorName}/>
                                          <CustomInput id='authorEmail' name='authorEmail' onChange={e => { setEmail(e.target.value) }} value={email} placeholder='E-mail *' onBlur={handleAuthorEmail}/>
                                          <CustomInput id='cellphone' name='cellphone' value={shownCellphone} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleCellPhoneChange(e) }} onBlur={handleCellphone} placeholder='Celular *' maxLength={15}/>
                                          <CustomSelect id='authorBoard' name='authorBoard' value={shownAuthorBoard} onChange={handleSelectAuthorBoard}>
                                            <option value={shownAuthorBoard} hidden disabled>{shownAuthorBoard} {shownAuthorBoard === 'Diretoria' ? '*' : ''}</option>
                                            {
                                              boards.map((item, index) => (
                                                <option key={index} value={item.id}>{item.label}</option>
                                              ))
                                            }
                                          </CustomSelect>
                                          <CustomSelect id='management' name='management' value={shownAuthorManagement} onChange={handleSelectAuthorManagement} disabled={managements.length === 0}>
                                            <option value={shownAuthorManagement} hidden disabled>{shownAuthorManagement} {shownAuthorManagement === 'Gerência' ? '*' : ''}</option>
                                            {
                                              managements.map((item, index) => (
                                                <option key={index} value={item.id}>{item.label}</option>
                                              ))
                                            }
                                          </CustomSelect>
                                          <button id='includeAuthor' onClick={handleIncludingAuthor}>{editable ? 'Alterar dados' : 'Incluir autor'}</button>
                                          <p>Você pode incluir até 5 autores</p>
                                        </S.InputsContainer>
                                      </S.IncludeAuthorsBox>
                                    </S.ColumnContainer>
                                    <S.ColumnContainer className='authors' width={55}>
                                      {
                                        screenWidth > 1000 && (
                                          <S.TitleContainer>
                                            <h2>Submissão de Trabalhos</h2>
                                            <h2>Incluir Autores</h2>
                                          </S.TitleContainer>
                                        )
                                      }
                                      <S.ListAuthors>
                                        <h3 className='first'>Autores:</h3>
                                        {
                                          includedAuthors.map((item, index) => (
                                            <S.SingleAuthor key={index}>
                                              <p>{item.name}</p>
                                              <Button onClick={() => { handleEditAuthor(index) }}><ModeOutlinedIcon className='icon' /></Button>
                                              <Button onClick={() => { handleDeleteAuthor(index) }}><DeleteForeverOutlinedIcon className='icon' /></Button>
                                            </S.SingleAuthor>
                                          ))
                                        }

                                      </S.ListAuthors>
                                      <p>Há alguma <strong>Triades</strong> de Segurança entre os autores do trabalho?</p>
                                      <p className='complement'><em>(Triades refere-se ao profissional formado no curso oficial pelo Núcleo de Segurança do SBIBAE)</em></p>
                                      <S.RowContainer style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <FormControl>
                                          <RadioGroup
                                            row
                                            id='leadership'
                                            name="leadership"
                                            value={triade}
                                            onChange={handleTriade}
                                          >
                                            <FormControlLabel value={true} control={<Radio />} label="Sim"/>
                                            <FormControlLabel value={false} control={<Radio />} label="Não"/>
                                          </RadioGroup>
                                        </FormControl>
                                        {
                                          triade && (
                                            <p className='complement'>Selecione os autores Triades:</p>
                                          )
                                        }
                                      </S.RowContainer>
                                      {
                                        triade && (
                                          <div style={{ width: '100%' }}>
                                            {
                                              includedAuthors.map((item, index) => (
                                                <S.SingleAuthor key={index}>
                                                  <FormControlLabel control={<Checkbox checked={item.triade} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleSelectAuthorTriade(e, item.id) }}/>} label={item.name} />
                                                </S.SingleAuthor>
                                              ))
                                            }
                                          </div>
                                        )
                                      }
                                    </S.ColumnContainer>
                                  </S.RowContainer>
                                  <S.ButtonsContainer>
                                    <Button variant='outlined' onClick={handleSaveAuthors}>Salvar</Button>
                                    {
                                      step2Percent === 30 && (
                                        <Button variant='outlined' onClick={changedStep2 ? handleModal : handleClickStep2}>Continuar para Etapa 3</Button>
                                      )
                                    }
                                  </S.ButtonsContainer>
                                </>
                              )
                              : step3
                                ? (
                                  <>
                                    <S.RowContainer style={{ justifyContent: 'space-between', alignItems: 'stretch' }}>
                                      <S.ColumnContainer width={55} style={{ justifyContent: 'flex-start' }}>
                                        <S.SimpleRowContainer>
                                          <S.SelectFileBox>
                                            <label htmlFor='file'>Escolher arquivo</label>
                                            <input {...getInputProps()} id='file' name='file' type='file' value={''} required accept='application/pdf' placeholder='Anexar Arquivo' onChange={(e) => { uploadValidation(e.target.files?.[0]) }}/>
                                            <div>
                                              <p>Somente arquivos em PDF</p>
                                              <p>Limite 100MB</p>
                                            </div>
                                          </S.SelectFileBox>
                                          <S.Step3DividerContainer>
                                            <p>OU</p>
                                          </S.Step3DividerContainer>
                                          <S.PDFBox>
                                            <div>
                                              <h3>Arraste aqui</h3>
                                            </div>
                                            <img src={pdfUpload} width={80} />
                                          </S.PDFBox>
                                        </S.SimpleRowContainer>
                                        {
                                          (file ?? fileInBase) && (
                                            <S.RowContainer style={{ justifyContent: 'center', backgroundColor: '#3b81f744', borderRadius: 8, marginBottom: 10 }}>
                                              <p><strong>Arquivo selecionado:</strong> {file ? file.name : fileInBase?.name}</p>
                                            </S.RowContainer>
                                          )
                                        }
                                        {
                                          (file && showAppendFileButton) && (
                                            <S.RowContainer style={{ justifyContent: 'center' }}>
                                              <Button variant='outlined'onClick={handleClickStep3}>Anexar arquivo (PDF)</Button>
                                            </S.RowContainer>
                                          )
                                        }
                                      </S.ColumnContainer>
                                      <S.ColumnContainer style={{ height: '100%' }}>
                                        {
                                          screenWidth > 1000 && (
                                            <S.TitleContainer>
                                              <h2>Submissão de Trabalhos</h2>
                                              <h2>Enviar Arquivo</h2>
                                            </S.TitleContainer>
                                          )
                                        }
                                        <S.StepsTextContainer>
                                          <p className='step3Paragrafy'>O trabalho deve estar no template correto para a categoria de inscrição, conforme o regulamento da Exposição da Qualidade disponível para download no site do evento. Deve ser submetido em formato PDF. Os trabalhos enviados com formatação fora do padrão não serão aprovados pela COMISSÃO TÉCNICO-CIENTÍFICA.</p>
                                        </S.StepsTextContainer>
                                      </S.ColumnContainer>
                                    </S.RowContainer>
                                  </>
                                )
                                : step4
                                  ? (
                                    <S.RowContainer justifycontent='flex-start'>
                                      <S.InformationContainer>
                                        <h2>Arquivo anexado com sucesso!</h2>
                                        <Button variant='outlined'onClick={handleClickStep4}>Revisar e finalizar</Button>
                                      </S.InformationContainer>
                                    </S.RowContainer>
                                  )
                                  : step5
                                    ? (
                                      <>
                                        <S.RowContainer style={{ justifyContent: 'space-between' }} className='step5Container'>
                                          <S.ColumnContainer width={52} className='summaryLastColumn'>
                                            <S.SummaryContainer>
                                              <S.RowContainer className='summaryRow title blueBorder'>
                                                <p className='summaryTitle'><strong>Título: </strong>{title}</p>
                                              </S.RowContainer>
                                              <S.RowContainer className='summaryRow noBreak'>
                                                {
                                                  code !== null && (
                                                    <p><strong>Código: </strong>{code}</p>
                                                  )
                                                }
                                                <p style={code !== null ? { marginLeft: 20 } : { marginLeft: 0 }}><strong>Diretoria: </strong>{shownBoard} / {shownManagement}</p>
                                              </S.RowContainer>
                                              <S.RowContainer className='summaryRow noBreak'>
                                                <p><strong>Categoria: </strong>{shownCategory} / {shownModel}</p>
                                              </S.RowContainer>
                                              <S.RowContainer className='summaryRow noBreak middleRow'>
                                                <p><strong className='topic'>Resumo: </strong>{briefSummary}</p>
                                              </S.RowContainer>
                                              <S.RowContainer style={{ alignItems: 'flex-start' }} className='summaryRow middleRow'>
                                                <S.ColumnContainer width={60} className='authorsStep5'>
                                                  <p className='topic'><strong>Autor{includedAuthors.length !== 1 ? 'es:' : ':'} </strong></p>
                                                  {
                                                    includedAuthors.map((item, index) => (
                                                      <S.SingleAuthor key={index}>
                                                        <p className='author'>{item.name}</p>
                                                      </S.SingleAuthor>
                                                    ))
                                                  }
                                                </S.ColumnContainer>
                                                <S.ColumnContainer className='noMarginTop summaryTagsContainer'>
                                                  <p className='topic'><strong>Palavras-chave:</strong></p>
                                                  {
                                                    tags.length > 0 && (

                                                      <S.TagsContainer>
                                                        {
                                                          tagsArray.map((item, index) => (
                                                            <p key={index} className='summaryInLineItem' style={{ marginRight: 5 }}>#{item.trim()}</p>))
                                                        }
                                                      </S.TagsContainer>
                                                    )
                                                  }
                                                </S.ColumnContainer>
                                              </S.RowContainer>
                                              {
                                                fileUrl && (
                                                  <S.RowContainer className='summaryRow blueBorder lastRow noBreak'>
                                                    <p>
                                                      <a href={fileUrl} target='_blank' rel="noreferrer">
                                                        <strong className='topic'>Arquivo: </strong>{fileInBase?.name}
                                                      </a>
                                                    </p>
                                                  </S.RowContainer>
                                                )
                                              }
                                            </S.SummaryContainer>
                                          </S.ColumnContainer>
                                          <S.ColumnContainer className='summaryLastColumn'>
                                            {
                                              screenWidth > 1000 && (
                                                <S.TitleContainer>
                                                  <h2>Submissão de Trabalhos</h2>
                                                  <h2>Resumo Completo</h2>
                                                </S.TitleContainer>
                                              )
                                            }
                                            <S.StepsTextContainer className='summaryAdvise'>
                                              {
                                                isDisabled
                                                  ? (
                                                    <>
                                                      <h3 className='summaryAdviseTitle'>Submissão de trabalhos encerrada</h3>
                                                      <p>O prazo de inscrições foi encerrado. Todos os trabalhos submetidos serão avaliados. Aguarde notificações.</p>
                                                      <p>Somente trabalhos devolvidos para correção poderão ser editados. Se você precisar corrigir seu trabalho, salve as alterações nas etapas correspondentes e clique no botão Atualizar Informações abaixo.</p></>
                                                  )
                                                  : (
                                                    <>
                                                      <h3 className='summaryAdviseTitle'>Leia antes de finalizar:</h3>
                                                      {
                                                        alreadySent
                                                          ? (
                                                            <p>Revise as informações cadastradas. Se realizar qualquer alteração, clique em Salvar na etapa correspondente e em seguida confirme o reevio clicando no botão Atualizar Informações abaixo.</p>
                                                          )
                                                          : (
                                                            <p>Revise as informações que você cadastrou em cada etapa antes de finalizar. Se quiser alterar alguma das etapas, use a barra de navegação das etapas.</p>
                                                          )
                                                      }
                                                    </>
                                                  )
                                              }
                                              <S.SimpleRowContainer>
                                                <Checkbox
                                                  sx={{
                                                    color: '#999'
                                                  }}
                                                  checked={checked}
                                                  onChange={handleChange}
                                                  id='terms'
                                                />
                                                <p className='checkboxLabel'>As informações cadastradas nas etapas anteriores estão corretas</p>
                                              </S.SimpleRowContainer>
                                            </S.StepsTextContainer>
                                            <Button variant='outlined' disabled={!checked} onClick={saveAllData}>{submitLoading ? 'Enviando' : alreadySent ? 'Atualizar informações' : 'Confirmar submissão'}</Button>
                                          </S.ColumnContainer>
                                        </S.RowContainer>
                                      </>
                                    )
                                    : null
                        }
                      </S.Form>
                    </>
                  )
              }
            </S.FormContainer>
          )
          : (
            <S.InformationContainer className='finalSubmission'>
              <h2>Olá {userName}. Tudo certo até aqui!</h2>
              <p>A submissão do trabalho <strong>{title}</strong> foi feita com sucesso.</p>
              <p>O trabalho será avaliado pela COMISSÃO TÉCNICO CIENTÍFICA do evento.</p>
              <p>Em breve você receberá uma notificação em seu e-mail cadastrado sobre o resultado da avaliação.</p>
              <Button variant='outlined'onClick={handleClickStep6}>Continuar para Meus Trabalhos</Button>
            </S.InformationContainer>
          )
      }
    </S.Container>
  )
}
