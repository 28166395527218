import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined'
// import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined'
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined'
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import { Link } from 'react-router-dom'
// import Badge from '@mui/material/Badge'
import { theme } from '../../config/theme'
/* import { useSelector } from 'react-redux'
import { type RootState } from '../../redux/store' */

interface Props {
  handleDrawerClose?: () => void
}

export const ListLinksAdmin = ({ handleDrawerClose }: Props): JSX.Element => {

  // const notifications = useSelector((state: RootState) => state.user.perfil?.notifications)

  const links = [
    {
      label: 'Dashboard',
      icon: InsertChartOutlinedIcon,
      route: '/admin'
    },
    {
      label: 'Eventos',
      icon: EventNoteOutlinedIcon,
      route: '/admin/eventos'
    },
    {
      label: 'Avaliadores',
      icon: AssignmentIndOutlinedIcon,
      route: '/admin/avaliadores'
    },
    {
      label: 'Distribuir Trabalhos',
      icon: LibraryBooksOutlinedIcon,
      route: '/admin/distribuir-trabalhos'
    },
    {
      label: 'Configurações',
      icon: SettingsOutlinedIcon,
      route: '/admin/configuracoes'
    },
    {
      label: 'Relatórios',
      icon: ReceiptLongOutlinedIcon,
      route: '/admin/relatorios'
    }
    /* {
      label: 'Notificações',
      icon: EmailOutlinedIcon,
      badge: notifications ?? 0,
      route: '/admin/notificacoes'
    } */
  ]

  return (
    <List>
      {links.map((info, index) => (
        <React.Fragment key={index}>
          <Link onClick={handleDrawerClose} style={{ color: 'inherit', textDecoration: 'none' }} to={info.route}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <info.icon color={location.pathname === info.route ? 'primary' : 'inherit'} />
                  {/*  <Badge max={9} badgeContent={info.badge} color="secondary">
                  </Badge> */}
                </ListItemIcon>
                <ListItemText primary={info.label} sx={{ color: location.pathname === info.route ? theme.palette.primary.main : 'inherit' }} />
              </ListItemButton>
            </ListItem>
          </Link>
        </React.Fragment>
      ))}
    </List>
  )
}
