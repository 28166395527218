import styled from 'styled-components'
import { theme } from '../../config/theme'

interface IRow {
  justifycontent?: string
}

interface IColumn {
  width?: number
}

const sm = 440
const md = 880
const lg = 1090

export const Container = styled.div`
  color: #ccc;
  margin: 0;
  padding: 10px;
`

export const ScreenContainer = styled.div`
 width: 95%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 h2{
    color: rgba(94, 53, 177, 0.7);
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
  .sp-bt {
    justify-content: space-between;
  }
  .css-1m9pwf3 {
    border: 1px solid #000;
  }
  .filters {
    width: 100%;
    // padding-bottom: 3px;
    margin: 7px 0 35px 0;
    border-bottom: 1px solid ${theme.palette.primary.main}
  }
  .top {
    margin-top: 15px;
  }
  p {
    color: #000;
  }
  span {
    color: #444;
  }
  .search {
    width: 55%;
  }
  .searchContainer {
    align-items: flex-end;
    align-self: stretch;
    justify-content: space-between;
    button {
      //margin-top: 8px;
      width: 100%;
    }
  }
  .buttonContainer {
    justify-content: space-evenly;
    margin: 10px auto 1px auto;
    padding: 10px;
  }
`

export const SelectsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SelectEvaluatorContainer = styled.div`
  width: 70%;
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  margin: 5px auto;
  p {
    color: #444;
  }
  .input {
    width: 60%;
  }
`

export const FormContainer = styled.div`
 width: 95%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 margin-bottom: 20px;
 border-left: 1px solid #3b81f7;
 @media (max-width: ${md}px) {
  border-left-width: 0;
  width: 85%;
  }
 h4 {
  color: #000;
  text-align: center;
  @media (max-width: ${sm}px) {
    font-size: 12px;
  }
  @media (min-width: ${sm}px) and (max-width: ${lg}px) {
    font-size: 13px;
  }
 }
`
export const ColumnContainer = styled.div<IColumn>`
  width: ${({ width }) => width ?? 10}%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0;
  .select {
    margin-bottom: 8px;
  }
`

export const RowContainer = styled.div<IRow>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ justifycontent }) => justifycontent ?? 'flex-start'};
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  aspect-ratio: 2.5/1;
`
export const SummaryContainer = styled.div`
  //width: 100%;
  .title {
      margin-bottom: 10px; 
      padding-bottom: 5px;     
    }
  .summaryRow {
    justify-content: flex-start;
    p {
      margin: 0;
    }
    .secondItem {
    margin-left: 20px;
    }
  }
  .summaryTitle {
    margin-top: 0;
    font-size: 20px;
  }
  .middleRow {
    margin-top: 20px;
    @media (max-width: ${md}px) {
      margin-top: 15px;
    }
  } 
  .lastRow {
    margin-top: 15px;
  } 
  .authorsStep5 {
    margin-top: 0;
    align-self: flex-start;
  }
  .blueBorder {
    border: 0 solid #3b81f799;
    border-bottom-width: 1px;
    padding-bottom: 1px;
  }
  .topic {
    font-size: 16px;
  }
  .author {
    font-size: 14px;
  }
  .summaryTagsContainer {
    @media (max-width: ${md}px) {
      margin-top: 15px;
    }
  }
  .noBreak {
    @media (max-width: ${md}px) {
      flex-direction: row;
  }
  }
  .description {
    p {
      font-size: 13px;
    }
  }

    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 8px;
    box-shadow: 24px;
    padding: 15px; */

`
export const SingleAuthor = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  @media (max-width: ${md}px) {
      justify-content: center;
    }
  p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 13.5px;
  }
`
export const TagsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 2px;
  @media (max-width: ${md}px) {
    justify-content: center;
  }
  .summaryInLineItem {
    margin-right: 8px;
    font-size: 14px;
  }
`
