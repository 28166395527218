import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined'
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined'
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined'
import { Link } from 'react-router-dom'
import Badge from '@mui/material/Badge'
import { theme } from '../../config/theme'
import { useSelector } from 'react-redux'
import { type RootState } from '../../redux/store'
import TvIcon from '@mui/icons-material/Tv'
import { eventLink } from '../../utils/eventLink.util'

interface Props {
  handleDrawerClose?: () => void
}

interface ILink {
  label: string
  icon: any
  badge?: number
  route: string
}

export const ListLinksUser = ({ handleDrawerClose }: Props): JSX.Element => {

  const needInfos = useSelector((state: RootState) => state.user.perfil?.needInfos)
  const notifications = useSelector((state: RootState) => state.user.perfil?.notifications)

  const [links, setLinks] = React.useState<ILink[]>([
    {
      label: 'Dashboard',
      icon: InsertChartOutlinedIcon,
      route: '/'
    },
    {
      label: 'Dados da inscrição',
      icon: AssignmentIndOutlinedIcon,
      route: '/perfil'
    }
  ])

  React.useEffect(() => {

    if (!needInfos) {
      setLinks([
        {
          label: 'Dashboard',
          icon: InsertChartOutlinedIcon,
          route: '/'
        },
        {
          label: 'Dados da inscrição',
          icon: AssignmentIndOutlinedIcon,
          route: '/perfil'
        },
        {
          label: 'Meus trabalhos',
          icon: LibraryBooksOutlinedIcon,
          route: '/trabalhos'
        },
        {
          label: 'Submissão de trabalhos',
          icon: PostAddOutlinedIcon,
          route: '/submissao'
        },
        {
          label: 'Notificações',
          icon: EmailOutlinedIcon,
          badge: notifications ?? 0,
          route: '/notificacoes'
        },
        {
          label: 'Acessar o Evento Online',
          icon: TvIcon,
          route: eventLink()
        }
      ])
    }
  }, [needInfos, notifications])

  return (
    <List>
      {links.map((info, index) => (
        <React.Fragment key={index}>
          <Link onClick={handleDrawerClose} style={{ color: 'inherit', textDecoration: 'none' }} to={info.route}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Badge max={9} badgeContent={info.badge} color="secondary">
                    <info.icon color={location.pathname === info.route ? 'primary' : 'inherit'} />
                  </Badge>
                </ListItemIcon>
                <ListItemText primary={info.label} sx={{ color: location.pathname === info.route ? theme.palette.primary.main : 'inherit' }} />
              </ListItemButton>
            </ListItem>
          </Link>
        </React.Fragment>
      ))}
    </List>
  )
}
