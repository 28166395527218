// Arquivo criado: 12/09/2023 às 23:16
import React from 'react'

import * as S from './styles'
import Button from '@mui/material/Button'
import { type RootState } from '../../redux/store'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import { useSelector, useDispatch } from 'react-redux'
import { backEnd } from '../../utils/backend.util'
import dayjs from 'dayjs'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import MenuItem from '@mui/material/MenuItem'
// import { urlFile } from '../../utils/urlFile.util'
import { setEventAction } from '../../redux/actions/event.action'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { DialogTitle } from '@mui/material'

interface Labels {
  label: string
    id: number
    count: number
}

interface EventDefault {
  id: number
  label: string
  default: boolean
  initialDate: Date
  finalDate: Date
  subscription: boolean
  assessment: boolean
  assessmentFinalDate: Date
  assessmentInitialDate: Date
  amountWorks: number
  labels: Labels[]
}

interface IEvents {
  label: string | undefined
  id: number | undefined
}

export const Settings = (): JSX.Element => {

  const dispatch = useDispatch()

  const event = useSelector((state: RootState) => state.event.data)
  const [loading, setLoading] = React.useState(false)
  const [enrollment, setEnrollment] = React.useState(false)
  const [evaluation, setEvaluation] = React.useState(false)

  const [eventDefault, setEventDefault] = React.useState<EventDefault | null>(null)

  const [events, setEvents] = React.useState<IEvents[]>([])
  const [eventId, setEventId] = React.useState(event?.id)

  const [choosenEvent, setChoosenEvent] = React.useState<EventDefault | null>(null)
  const [choosenEventId, setChoosenEventId] = React.useState<number | null>(null)
  const [newEventDefault, setNewEventDefault] = React.useState(false)

  const [filePolicies, setFilePolicies] = React.useState<File | undefined>(undefined)
  /* const [filePlace, setFilePlace] = React.useState<File | undefined>(undefined)
  const [hasDownloaded, setHasDownloaded] = React.useState(false) */

  const [enrollmentStartDate, setEnrollmentStartDate] = React.useState<Date | undefined>(undefined)
  const [maskEnrollmentStartDate, setMaskEnrollmentStartDate] = React.useState<string>('')
  const [enrollmentStartHour, setEnrollmentStartHour] = React.useState<string>('')
  const [enrollmentEndDate, setEnrollmentEndDate] = React.useState<Date | undefined>(undefined)
  const [maskEnrollmentEndDate, setMaskEnrollmentEndDate] = React.useState<string>('')
  const [enrollmentEndHour, setEnrollmentEndHour] = React.useState<string>('')
  const [evaluationStartDate, setEvaluationStartDate] = React.useState<Date | undefined>(undefined)
  const [maskEvaluationStartDate, setMaskEvaluationStartDate] = React.useState<string>('')
  const [evaluationStartHour, setEvaluationStartHour] = React.useState<string>('')
  const [evaluationEndDate, setEvaluationEndDate] = React.useState<Date | undefined>(undefined)
  const [maskEvaluationEndDate, setMaskEvaluationEndDate] = React.useState<string>('')
  const [evaluationEndHour, setEvaluationEndHour] = React.useState<string>('')
  const [changedEnrollmentStart, setChangedEnrollmentStart] = React.useState(false)
  const [changedEnrollmentEnd, setChangedEnrollmentEnd] = React.useState(false)
  const [changedEvaluationStart, setChangedEvaluationStart] = React.useState(false)
  const [changedEvaluationEnd, setChangedEvaluationEnd] = React.useState(false)
  const [get, setGet] = React.useState(false)
  const [toggle, setToggle] = React.useState(false)

  const [editedEvent, setEditedEvent] = React.useState<EventDefault | null>(null)
  const [editedEventId, setEditedEventId] = React.useState<number | null>(null)
  const [editOtherEvent, setEditOtherEvent] = React.useState(false)
  const [label, setLabel] = React.useState('')

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      if (!event) return

      setLoading(true)
      const response = await backEnd('GET', `events/${event.id}`)
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg,
          modal: {
            title: 'Ops...'
          }
        }))

        return
      }

      const today = new Date()

      setEventDefault(response.data)
      setEventId(response.data.id)
      setEnrollment(response.data.initialDate <= today.toISOString() && response.data.finalDate >= today.toISOString())
      setEvaluation(response.data.assessmentInitialDate <= today.toISOString() && response.data.assessmentFinalDate >= today.toISOString())
      setEnrollmentStartDate(response.data.initialDate)
      setEnrollmentEndDate(response.data.finalDate)
      setEvaluationStartDate(response.data.assessmentInitialDate)
      setEvaluationEndDate(response.data.assessmentFinalDate)
      setLabel(response.data.label)

      if (get) {
        setGet(false)
      }

    }

    void getData()
  }, [dispatch, event, get])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      const response = await backEnd('GET', 'events')

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg,
          modal: {
            title: 'Ops...'
          }
        }))

        return
      }

      setEvents(response.data)
    }

    void getData()
  }, [dispatch])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      if (!choosenEventId) return

      setLoading(true)
      const response = await backEnd('GET', `events/${choosenEventId}`)
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg,
          modal: {
            title: 'Ops...'
          }
        }))

        return
      }

      setChoosenEvent(response.data)

    }

    void getData()
  }, [dispatch, choosenEventId])

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      if (!editedEventId) return

      setLoading(true)
      const response = await backEnd('GET', `events/${editedEventId}`)
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg,
          modal: {
            title: 'Ops...'
          }
        }))

        return
      }

      setEditedEvent(response.data)
      setEnrollmentStartDate(response.data.initialDate)
      setEnrollmentEndDate(response.data.finalDate)
      setMaskEnrollmentStartDate(dayjs(response.data.initialDate).format('DD/MM/YYYY'))
      setEnrollmentStartHour(dayjs(response.data.initialDate).format('HH:mm'))
      setMaskEnrollmentEndDate(dayjs(response.data.finalDate).format('DD/MM/YYYY'))
      setEnrollmentEndHour(dayjs(response.data.finalDate).format('HH:mm'))
      setEvaluationStartDate(response.data.assessmentInitialDate)
      setEvaluationEndDate(response.data.assessmentFinalDate)
      setMaskEvaluationStartDate(dayjs(response.data.assessmentInitialDate).format('DD/MM/YYYY'))
      setEvaluationStartHour(dayjs(response.data.assessmentInitialDate).format('HH:mm'))
      setMaskEvaluationEndDate(dayjs(response.data.assessmentFinalDate).format('DD/MM/YYYY'))
      setEvaluationEndHour(dayjs(response.data.assessmentFinalDate).format('HH:mm'))
      setLabel(response.data.label)

    }

    void getData()
  }, [dispatch, editedEventId])

  const handleSelectedEvent = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const id = Number(e.target.value)
    setChoosenEventId(id)
    setNewEventDefault(true)
  }

  const handleEditeOtherEvent = (e: React.ChangeEvent<HTMLInputElement>): void => {
    cancelEventEdition()
    const id = Number(e.target.value)
    setEditedEventId(id)
    setEditOtherEvent(true)
  }

  const handleForm = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
  }

  const handleEnrollment = (): void => {
    const today = new Date()
    if (eventDefault) {
      if (enrollment) {
        setMaskEnrollmentStartDate(dayjs(eventDefault.initialDate).format('DD/MM/YYYY'))
        setEnrollmentStartHour(dayjs(eventDefault.initialDate).format('HH:mm'))
        setMaskEnrollmentEndDate(dayjs(today).format('DD/MM/YYYY'))
        setEnrollmentEndHour(dayjs(today).format('HH:mm'))
        setEnrollmentEndDate(today)
      }
      if (!enrollment) {
        setMaskEnrollmentEndDate(dayjs(eventDefault.finalDate).format('DD/MM/YYYY'))
        setEnrollmentEndHour(dayjs(eventDefault.finalDate).format('HH:mm'))
        setMaskEnrollmentStartDate(dayjs(today).format('DD/MM/YYYY'))
        setEnrollmentStartHour(dayjs(today).format('HH:mm'))
        setEnrollmentStartDate(today)
      }
      setMaskEvaluationStartDate(dayjs(eventDefault.assessmentInitialDate).format('DD/MM/YYYY'))
      setEvaluationStartHour(dayjs(eventDefault.assessmentInitialDate).format('HH:mm'))
      setMaskEvaluationEndDate(dayjs(eventDefault.assessmentFinalDate).format('DD/MM/YYYY'))
      setEvaluationEndHour(dayjs(eventDefault.assessmentFinalDate).format('HH:mm'))
    }
    setEnrollment(!enrollment)
    setToggle(true)
    setEditOtherEvent(false)
  }

  const handleEvaluation = (): void => {
    const today = new Date()
    if (eventDefault) {
      if (evaluation) {
        setMaskEvaluationStartDate(dayjs(eventDefault.assessmentInitialDate).format('DD/MM/YYYY'))
        setEvaluationStartHour(dayjs(eventDefault.assessmentInitialDate).format('HH:mm'))
        setMaskEvaluationEndDate(dayjs(today).format('DD/MM/YYYY'))
        setEvaluationEndHour(dayjs(today).format('HH:mm'))
        setEvaluationEndDate(today)
      }
      if (!evaluation) {
        setMaskEvaluationEndDate(dayjs(eventDefault.assessmentFinalDate).format('DD/MM/YYYY'))
        setEvaluationEndHour(dayjs(eventDefault.assessmentFinalDate).format('HH:mm'))
        setMaskEvaluationStartDate(dayjs(today).format('DD/MM/YYYY'))
        setEvaluationStartHour(dayjs(today).format('HH:mm'))
        setEvaluationStartDate(today)
      }
      setMaskEnrollmentStartDate(dayjs(eventDefault.initialDate).format('DD/MM/YYYY'))
      setEnrollmentStartHour(dayjs(eventDefault.initialDate).format('HH:mm'))
      setMaskEnrollmentEndDate(dayjs(eventDefault.finalDate).format('DD/MM/YYYY'))
      setEnrollmentEndHour(dayjs(eventDefault.finalDate).format('HH:mm'))
    }
    setEvaluation(!evaluation)
    setToggle(true)
    setEditOtherEvent(false)
  }

  const cancelEventEdition = (): void => {
    if (eventDefault) {
      setEnrollmentStartDate(eventDefault?.initialDate)
      setEnrollmentEndDate(eventDefault?.finalDate)
      setEvaluationStartDate(eventDefault?.assessmentInitialDate)
      setEvaluationEndDate(eventDefault?.assessmentFinalDate)
      setEnrollment(eventDefault?.subscription)
      setEvaluation(eventDefault?.assessment)
      setLabel(eventDefault.label)
    } else {
      setEnrollmentStartDate(undefined)
      setEnrollmentEndDate(undefined)
      setEvaluationStartDate(undefined)
      setEvaluationEndDate(undefined)
      setLabel('')
    }
    setMaskEnrollmentStartDate('')
    setEnrollmentStartHour('')
    setMaskEnrollmentEndDate('')
    setEnrollmentEndHour('')
    setMaskEvaluationStartDate('')
    setEvaluationStartHour('')
    setMaskEvaluationEndDate('')
    setEvaluationEndHour('')
    setChangedEnrollmentEnd(false)
    setChangedEnrollmentStart(false)
    setChangedEvaluationEnd(false)
    setChangedEvaluationStart(false)
    setToggle(false)
  }

  const cancelOtherEventEdition = (): void => {
    cancelEventEdition()
    setEditedEventId(null)
    setEditedEvent(null)
    setEditOtherEvent(false)
  }

  const uploadPoliciesValidation = (file: File | undefined): void => {
    if (file) {

      const isPDF = /(\.pdf)$/i

      if (!isPDF.exec(file.name)) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O arquivo selecionado deve possuir extensão .pdf',
          type: 'error'
        }))

        return
      }

      if (file.name.length > 255) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O nome do arquivo selecionado é maior que 255 caracteres',
          type: 'error'
        }))

        return
      }

      setFilePolicies(file)
    }
  }

  const uploadFilePolicies = async (): Promise<void> => {

    if (!filePolicies) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Por favor, selecione um arquivo PDF',
        type: 'error'
      }))

      return
    }
    const formData = new FormData()
    formData.append('file', filePolicies)

    const response = await backEnd(
      'POST',
      'addons/policies/upload',
      formData,
      'multipart/form-data'
    )

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg,
        type: 'error'
      }))

      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Arquivo de Política de Privacidade atualizado com sucesso.',
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))

    setFilePolicies(undefined)
  }

  /* const uploadPlaceValidation = (file: File | undefined): void => {
    if (file) {

      const isSpreadSheet1 = /(\.xlsx)$/i
      const isSpreadSheet2 = /(\.xls)$/i

      if (!isSpreadSheet1.exec(file.name) && !isSpreadSheet2.exec(file.name)) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O arquivo selecionado deve possuir extensão .xls ou .xlsx',
          type: 'error'
        }))

        return
      }

      if (file.name.length > 255) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O nome do arquivo selecionado é maior que 255 caracteres',
          type: 'error'
        }))

        return
      }

      setFilePlace(file)
    }
  } */

  /*  const uploadFilePlace = async (): Promise<void> => {

    if (!filePlace) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Por favor, selecione uma planilha',
        type: 'error'
      }))

      return
    }
    const formData = new FormData()
    formData.append('file', filePlace)

    const response = await backEnd(
      'PUT',
      'works/spreadsheet',
      formData,
      'multipart/form-data'
    )

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg,
        type: 'error'
      }))

      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Importação de arquivo realizada com sucesso.',
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))

    setFilePlace(undefined)
  } */

  /* const getSpreadsheet = async (): Promise<void> => {

    if (!eventDefault) return

    const url = await urlFile(`works/event/${eventDefault.id}/spreadsheet/full`)

    const link = document.createElement('a')
    link.href = url
    link.download = 'submissoes.xlsx'
    link.click()

    window.URL.revokeObjectURL(url)
    setHasDownloaded(true)
  } */

  const maskDate = (value: string): string => {
    const v = value.replace(/\D/g, '').slice(0, 8)
    if (v.length >= 5) {
      return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`
    } else if (v.length >= 3) {
      return `${v.slice(0, 2)}/${v.slice(2)}`
    }
    return v
  }

  const maskHour = (value: string): string => {
    const v = value.replace(/\D/g, '').slice(0, 4)
    if (v.length >= 4) {
      return `${v.slice(0, 2)}:${v.slice(2, 4)}`
    }
    return v
  }

  const handleMaskEnrollmentStartDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const maskStartDate = maskDate(event.target.value)
    setMaskEnrollmentStartDate(maskStartDate)
    if (enrollmentStartHour !== '') {
      const dateParts = maskStartDate.split('/')
      const timeParts = enrollmentStartHour.split(':')
      const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], 0)
      setEnrollmentStartDate(date)
      setChangedEnrollmentStart(true)
    }
  }

  const handleEnrollmentStartHour = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const maskStartHour = maskHour(event.target.value)
    setEnrollmentStartHour(maskStartHour)
    if (maskEnrollmentStartDate !== '') {
      const dateParts = maskEnrollmentStartDate.split('/')
      const timeParts = maskStartHour.split(':')
      const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], 0)
      setEnrollmentStartDate(date)
      setChangedEnrollmentStart(true)
    }
  }

  const handleMaskEnrollmentEndDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const maskEndDate = maskDate(event.target.value)
    setMaskEnrollmentEndDate(maskEndDate)
    if (enrollmentEndHour !== '') {
      const dateParts = maskEndDate.split('/')
      const timeParts = enrollmentEndHour.split(':')
      const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], 0)
      setEnrollmentEndDate(date)
      setChangedEnrollmentEnd(true)
    }
  }

  const handleEnrollmentEndHour = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const maskEndHour = maskHour(event.target.value)
    setEnrollmentEndHour(maskEndHour)
    if (maskEnrollmentEndDate !== '') {
      const dateParts = maskEnrollmentEndDate.split('/')
      const timeParts = maskEndHour.split(':')
      const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], 0)
      setEnrollmentEndDate(date)
      setChangedEnrollmentEnd(true)
    }
  }

  const handleMaskEvaluationStartDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const maskStartDate = maskDate(event.target.value)
    setMaskEvaluationStartDate(maskStartDate)
    if (enrollmentStartHour !== '') {
      const dateParts = maskStartDate.split('/')
      const timeParts = evaluationStartHour.split(':')
      const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], 0)
      setEvaluationStartDate(date)
      setChangedEvaluationStart(true)
    }
  }

  const handleEvaluatonStartHour = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const maskStartHour = maskHour(event.target.value)
    setEvaluationStartHour(maskStartHour)
    if (maskEvaluationStartDate !== '') {
      const dateParts = maskEvaluationStartDate.split('/')
      const timeParts = maskStartHour.split(':')
      const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], 0)
      setEvaluationStartDate(date)
      setChangedEvaluationStart(true)
    }
  }

  const handleMaskEvaluationEndDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const maskEndDate = maskDate(event.target.value)
    setMaskEvaluationEndDate(maskEndDate)
    if (enrollmentEndHour !== '') {
      const dateParts = maskEndDate.split('/')
      const timeParts = evaluationEndHour.split(':')
      const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], 0)
      setEvaluationEndDate(date)
      setChangedEvaluationEnd(true)
    }
  }

  const handleEvaluatonEndHour = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const maskEndHour = maskHour(event.target.value)
    setEvaluationEndHour(maskEndHour)
    if (maskEvaluationEndDate !== '') {
      const dateParts = maskEvaluationEndDate.split('/')
      const timeParts = maskEndHour.split(':')
      const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0], +timeParts[0], +timeParts[1], 0)
      setEvaluationEndDate(date)
      setChangedEvaluationEnd(true)
    }
  }

  const handleLabel = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setLabel(event.target.value)
  }

  const handleEventUpdate = async (): Promise<void> => {

    if (changedEnrollmentStart) {
      if (!enrollmentStartDate || enrollmentStartDate === undefined) {
        dispatch(setOpenGlobalMessageAction({
          message: 'A data e o horário de início das inscrições devem ser informados.',
          type: 'error'
        }))
        return
      }

      if (!maskEnrollmentStartDate || maskEnrollmentStartDate === '') {
        dispatch(setOpenGlobalMessageAction({
          message: 'A data de início das inscrições deve ser informada.',
          type: 'error'
        }))
        return
      }

      if (!(/(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/.test(maskEnrollmentStartDate))) {
        dispatch(setOpenGlobalMessageAction({
          message: 'Data de início das inscrições inválida.',
          type: 'error'
        }))

        return
      }
      // hour
      if (!enrollmentStartHour || enrollmentStartHour === '') {
        dispatch(setOpenGlobalMessageAction({
          message: 'O horário de início das inscrições deve ser informado.',
          type: 'error'
        }))

        return
      }

      if (!(/^(([01][0-9]|2[0-3])h)|(([01][0-9]|2[0-3]):[0-5][0-9])$/.test(enrollmentStartHour))) {
        dispatch(setOpenGlobalMessageAction({
          message: 'Horário de início das inscrições inválido. O hoŕario deve conter 2 dígitos para hora (formato 24h) e 2 dígitos para os minutos.',
          type: 'error'
        }))

        return
      }
    }

    if (changedEnrollmentEnd) {
      if (!enrollmentEndDate || enrollmentEndDate === undefined) {
        dispatch(setOpenGlobalMessageAction({
          message: 'A data e o horário de término das inscrições devem ser informados.',
          type: 'error'
        }))
        return
      }

      if (!maskEnrollmentEndDate || maskEnrollmentEndDate === '') {
        dispatch(setOpenGlobalMessageAction({
          message: 'A data de término das inscrições deve ser informada.',
          type: 'error'
        }))
        return
      }

      if (!(/(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/.test(maskEnrollmentEndDate))) {
        dispatch(setOpenGlobalMessageAction({
          message: 'Data de término das inscrições inválida.',
          type: 'error'
        }))

        return
      }
      // hour
      if (!enrollmentEndHour || enrollmentEndHour === '') {
        dispatch(setOpenGlobalMessageAction({
          message: 'O horário de término das inscrições deve ser informado.',
          type: 'error'
        }))

        return
      }

      if (!(/^(([01][0-9]|2[0-3])h)|(([01][0-9]|2[0-3]):[0-5][0-9])$/.test(enrollmentEndHour))) {
        dispatch(setOpenGlobalMessageAction({
          message: 'Horário de término das inscrições inválido. O hoŕario deve conter 2 dígitos para hora (formato 24h) e 2 dígitos para os minutos.',
          type: 'error'
        }))

        return
      }
    }

    if (changedEvaluationStart) {
      if (!evaluationStartDate || evaluationStartDate === undefined) {
        dispatch(setOpenGlobalMessageAction({
          message: 'A data e o horário de início das avaliações devem ser informados.',
          type: 'error'
        }))
        return
      }

      if (!maskEvaluationStartDate || maskEvaluationStartDate === '') {
        dispatch(setOpenGlobalMessageAction({
          message: 'A data de início das avaliações deve ser informada.',
          type: 'error'
        }))
        return
      }

      if (!(/(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/.test(maskEvaluationStartDate))) {
        dispatch(setOpenGlobalMessageAction({
          message: 'Data de início das avaliações inválida.',
          type: 'error'
        }))

        return
      }
      // hour
      if (!evaluationStartHour || evaluationStartHour === '') {
        dispatch(setOpenGlobalMessageAction({
          message: 'O horário de início das avaliações deve ser informado.',
          type: 'error'
        }))

        return
      }

      if (!(/^(([01][0-9]|2[0-3])h)|(([01][0-9]|2[0-3]):[0-5][0-9])$/.test(evaluationStartHour))) {
        dispatch(setOpenGlobalMessageAction({
          message: 'Horário de início das avaliações inválido. O hoŕario deve conter 2 dígitos para hora (formato 24h) e 2 dígitos para os minutos.',
          type: 'error'
        }))

        return
      }
    }

    if (changedEvaluationEnd) {
      if (!evaluationEndDate || evaluationEndDate === undefined) {
        dispatch(setOpenGlobalMessageAction({
          message: 'A data e o horário de término das avaliações devem ser informados.',
          type: 'error'
        }))
        return
      }

      if (!maskEvaluationEndDate || maskEvaluationEndDate === '') {
        dispatch(setOpenGlobalMessageAction({
          message: 'A data de término das avaliações deve ser informada.',
          type: 'error'
        }))
        return
      }

      if (!(/(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/.test(maskEvaluationEndDate))) {
        dispatch(setOpenGlobalMessageAction({
          message: 'Data de término das avaliações inválida.',
          type: 'error'
        }))

        return
      }
      // hour
      if (!evaluationEndHour || evaluationEndHour === '') {
        dispatch(setOpenGlobalMessageAction({
          message: 'O horário de término das avaliações deve ser informado.',
          type: 'error'
        }))

        return
      }

      if (!(/^(([01][0-9]|2[0-3])h)|(([01][0-9]|2[0-3]):[0-5][0-9])$/.test(evaluationEndHour))) {
        dispatch(setOpenGlobalMessageAction({
          message: 'Horário de término das avaliações inválido. O hoŕario deve conter 2 dígitos para hora (formato 24h) e 2 dígitos para os minutos.',
          type: 'error'
        }))

        return
      }
    }

    if (!enrollmentStartDate) return
    if (!enrollmentEndDate) return
    if (!evaluationStartDate) return
    if (!evaluationEndDate) return

    const today = new Date()

    const isEnrollment = new Date(enrollmentStartDate) <= today && new Date(enrollmentEndDate) >= today
    const isEvaluation = new Date(evaluationStartDate) <= today && new Date(evaluationEndDate) >= today

    const data = {
      initialDate: enrollmentStartDate,
      finalDate: enrollmentEndDate,
      assessmentInitialDate: evaluationStartDate,
      assessmentFinalDate: evaluationEndDate,
      label,
      subscription: isEnrollment,
      assessment: isEvaluation
    }

    if (editOtherEvent) {

      if (!editedEventId) return

      setLoading(true)
      const response = await backEnd('PUT', `events/${editedEventId}`, data)
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg,
          type: 'error'
        }))
        return
      }

      dispatch(setOpenGlobalMessageAction({
        message: 'Evento atualizado com sucesso.',
        type: 'success',
        modal: {
          title: 'Sucesso!'
        }
      }))

      setToggle(false)

    }

    if (toggle) {

      if (!eventId) return

      setLoading(true)
      const response = await backEnd('PUT', `events/${eventId}`, data)
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg,
          type: 'error'
        }))
        return
      }

      dispatch(setOpenGlobalMessageAction({
        message: 'Evento atualizado com sucesso.',
        type: 'success',
        modal: {
          title: 'Sucesso!'
        }
      }))

    }

    setEnrollmentStartDate(undefined)
    setMaskEnrollmentStartDate('')
    setEnrollmentStartHour('')
    setEnrollmentEndDate(undefined)
    setMaskEnrollmentEndDate('')
    setEnrollmentEndHour('')
    setEvaluationStartDate(undefined)
    setMaskEvaluationStartDate('')
    setEvaluationStartHour('')
    setEvaluationEndDate(undefined)
    setMaskEvaluationEndDate('')
    setEvaluationEndHour('')
    setLabel('')
    setChangedEnrollmentEnd(false)
    setChangedEnrollmentStart(false)
    setChangedEvaluationEnd(false)
    setChangedEvaluationStart(false)
    setToggle(false)
    setEditOtherEvent(false)
    setEditedEvent(null)
    setEditedEventId(null)
    setGet(true)
  }

  const handleNewEventDefault = async (): Promise<void> => {

    if (!choosenEventId) return

    const data = {
      default: true
    }

    const response = await backEnd('PUT', `events/${choosenEventId}`, data)
    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg,
        type: 'error'
      }))

      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Alteração de evento padrão realizada com sucesso.',
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))

    setEventDefault(response.data)

    const response2 = await backEnd('GET', 'events/default')

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg,
        type: 'error'
      }))

      return
    }

    dispatch(setEventAction(response2.data))
    setEventDefault(response2.data)

    setChoosenEvent(null)
    setChoosenEventId(null)
    setNewEventDefault(false)
    cancelEventEdition()
  }
  return (
    <S.Container>
      <Dialog
        open={editOtherEvent}
        onClose={cancelOtherEventEdition}
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle>
          Editar Evento <em>{editedEvent?.label}</em>
        </DialogTitle>
        <DialogContent>
          <S.FormGroupsContainer>
            <S.RowContainer className='groupLine'>
              <S.ColumnContainer width={100}>
                <h4>Título do Evento</h4>
                <S.RowContainer>
                  <TextField fullWidth required size='small' id='eventLabel' name='eventLabel' value={label} onChange={handleLabel} label='Título do Evento'/>
                </S.RowContainer>
                <h4 className='noMarginBottom'>Editar Data/Hora Submissões</h4>
                <S.RowContainer>
                  <S.ColumnContainer className='groupInputs'>
                    <p>Início:</p>
                    <TextField required size='small' id='enrollmentStartDate' name='enrollmentStartDate' value={maskEnrollmentStartDate} onChange={handleMaskEnrollmentStartDate} label='dd/mm/aaaa'/>
                    <TextField required size='small' id='enrollmentStartHour' name='enrollmantStartHour' value={enrollmentStartHour} onChange={handleEnrollmentStartHour} label='--:--'/>
                  </S.ColumnContainer>
                  <S.ColumnContainer className='groupInputs'>
                    <p>Término:</p>
                    <TextField required size='small' id='enrollmentEndDate' name='enrollmentEndDate' value={maskEnrollmentEndDate} onChange={handleMaskEnrollmentEndDate} label='dd/mm/aaaa'/>
                    <TextField required size='small' id='enrollmentEndHour' name='enrollmentEndHour' value={enrollmentEndHour} onChange={handleEnrollmentEndHour} label='--:--'/>
                  </S.ColumnContainer>
                </S.RowContainer>
                <h4 className='noMarginBottom'>Editar Data/Hora das Avaliações</h4>
                <S.RowContainer>
                  <S.ColumnContainer className='groupInputs'>
                    <p>Início:</p>
                    <TextField required size='small' id='evaluationStartDate' name='evaluationStartDate' value={maskEvaluationStartDate} onChange={handleMaskEvaluationStartDate} label='dd/mm/aaaa'/>
                    <TextField required size='small' id='evaluationStartHour' name='evaluationStartHour' value={evaluationStartHour} onChange={handleEvaluatonStartHour} label='--:--'/>
                  </S.ColumnContainer>
                  <S.ColumnContainer className='groupInputs'>
                    <p>Término:</p>
                    <TextField required size='small' id='evaluationEndtDate' name='evaluationEndtDate' value={maskEvaluationEndDate} onChange={handleMaskEvaluationEndDate} label='dd/mm/aaaa'/>
                    <TextField required size='small' id='evaluationEndtHour' name='evaluationEndtHour' value={evaluationEndHour} onChange={handleEvaluatonEndHour} label='--:--'/>
                  </S.ColumnContainer>
                </S.RowContainer>
              </S.ColumnContainer>
            </S.RowContainer>
          </S.FormGroupsContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelOtherEventEdition}>Cancelar edição</Button>
          <Button className='button' onClick={handleEventUpdate}>Salvar Configurações</Button>
        </DialogActions>
      </Dialog>
      <S.ScreenContainer>
        {
          loading
            ? (
              <S.LoadingContainer>
                <CircularProgress />
              </S.LoadingContainer>
            )
            : (
              <>
                <S.RowContainer style={{ alignItems: 'stretch' }}>
                  <S.ColumnContainer className='eventInformation' width={45}>
                    <h2>Evento Atual</h2>
                    <S.FormContainer>
                      <form onSubmit={handleForm}>
                        <S.FormGroupsContainer className='formTop'>
                          <S.RowContainer>
                            <FormGroup className='switchesBox'>
                              <FormControlLabel control={<Switch checked={enrollment} onChange={handleEnrollment}/>} label="Submissões" />
                              <FormControlLabel control={<Switch checked={evaluation} onChange={handleEvaluation} />} label="Avaliações" />
                            </FormGroup>
                          </S.RowContainer>
                        </S.FormGroupsContainer>
                        <S.FormGroupsContainer>
                          {
                            event && (
                              <>
                                <S.RowContainer style={{ justifyContent: 'center' }}>
                                  <S.ColumnContainer width={100}>
                                    <p className='emphasis'>Evento em Atividade:</p>
                                    <p className='information'>{eventDefault?.label}</p>
                                  </S.ColumnContainer>
                                </S.RowContainer>
                                <S.RowContainer style={{ justifyContent: 'center' }}>
                                  <S.ColumnContainer width={49}>
                                    <h4>Submissões</h4>
                                    <p className='emphasis'>Início: <span className='information'>{dayjs(eventDefault?.initialDate).format('DD/MM/YYYY - HH:mm')}</span></p>
                                    <p className='emphasis'>Término: <span className='information'>{dayjs(eventDefault?.finalDate).format('DD/MM/YYYY - HH:mm')}</span></p>
                                  </S.ColumnContainer>
                                  <S.ColumnContainer width={49}>
                                    <h4>Avaliações</h4>
                                    <p className='emphasis'>Início: <span className='information'>{dayjs(eventDefault?.assessmentInitialDate).format('DD/MM/YYYY - HH:mm')}</span></p>
                                    <p className='emphasis'>Término: <span className='information'>{dayjs(eventDefault?.assessmentFinalDate).format('DD/MM/YYYY - HH:mm')}</span></p>
                                  </S.ColumnContainer>
                                </S.RowContainer>
                              </>
                            )
                          }
                        </S.FormGroupsContainer>
                        {
                          toggle
                            ? (
                              <>
                                <S.FormGroupsContainer>
                                  <S.RowContainer className='groupLine'>
                                    <S.ColumnContainer width={100}>
                                      <h4 className='noMarginBottom'>Editar Data/Hora Submissões</h4>
                                      <S.RowContainer>
                                        <S.ColumnContainer className='groupInputs'>
                                          <p>Início:</p>
                                          <TextField required size='small' id='enrollmentStartDate' name='enrollmentStartDate' value={maskEnrollmentStartDate} onChange={handleMaskEnrollmentStartDate} label='dd/mm/aaaa'/>
                                          <TextField required size='small' id='enrollmentStartHour' name='enrollmantStartHour' value={enrollmentStartHour} onChange={handleEnrollmentStartHour} label='--:--'/>
                                        </S.ColumnContainer>
                                        <S.ColumnContainer className='groupInputs'>
                                          <p>Término:</p>
                                          <TextField required size='small' id='enrollmentEndDate' name='enrollmentEndDate' value={maskEnrollmentEndDate} onChange={handleMaskEnrollmentEndDate} label='dd/mm/aaaa'/>
                                          <TextField required size='small' id='enrollmentEndHour' name='enrollmentEndHour' value={enrollmentEndHour} onChange={handleEnrollmentEndHour} label='--:--'/>
                                        </S.ColumnContainer>
                                      </S.RowContainer>
                                      <h4 className='noMarginBottom'>Editar Data/Hora das Avaliações</h4>
                                      <S.RowContainer>
                                        <S.ColumnContainer className='groupInputs'>
                                          <p>Início:</p>
                                          <TextField required size='small' id='evaluationStartDate' name='evaluationStartDate' value={maskEvaluationStartDate} onChange={handleMaskEvaluationStartDate} label='dd/mm/aaaa'/>
                                          <TextField required size='small' id='evaluationStartHour' name='evaluationStartHour' value={evaluationStartHour} onChange={handleEvaluatonStartHour} label='--:--'/>
                                        </S.ColumnContainer>
                                        <S.ColumnContainer className='groupInputs'>
                                          <p>Término:</p>
                                          <TextField required size='small' id='evaluationEndtDate' name='evaluationEndtDate' value={maskEvaluationEndDate} onChange={handleMaskEvaluationEndDate} label='dd/mm/aaaa'/>
                                          <TextField required size='small' id='evaluationEndtHour' name='evaluationEndtHour' value={evaluationEndHour} onChange={handleEvaluatonEndHour} label='--:--'/>
                                        </S.ColumnContainer>
                                      </S.RowContainer>
                                    </S.ColumnContainer>
                                  </S.RowContainer>
                                </S.FormGroupsContainer>
                                <S.RowContainer style={{ alignSelf: 'flex-start', justifyContent: 'space-evenly' }}>
                                  <Button className='button' variant='outlined' onClick={cancelEventEdition}>Cancelar Edição</Button>
                                  <Button className='button' variant='outlined' onClick={handleEventUpdate}>Salvar Configurações</Button>
                                </S.RowContainer>
                              </>
                            )
                            : (
                              <Button variant='outlined' onClick={() => { setToggle(true) }}>Editar Evento</Button>
                            )
                        }
                        {/* <S.RowContainer className='placeContainer'>
                          <S.ColumnContainer width={100}>
                            <h4 className='noMarginBottom'>Importar Local e Data de Apresentação</h4>
                            <Button variant='outlined' className='button marginTop' onClick={getSpreadsheet}>Baixar Modelo</Button>
                            <S.ButtonContainer>
                            </S.ButtonContainer>
                            {
                              hasDownloaded && (
                                <S.RowContainer>
                                  <S.SelectFileBox>
                                    <label htmlFor='file'>ESCOLHER ARQUIVO</label>
                                    <input id='file' name='file' type='file' value={''} required accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' placeholder='Anexar Arquivo' onChange={(e) => { uploadPlaceValidation(e.target.files?.[0]) }}/>
                                  </S.SelectFileBox>
                                </S.RowContainer>
                              )
                            }
                            {
                              (filePlace) && (
                                <S.RowContainer style={{ justifyContent: 'center', backgroundColor: '#3b81f744', borderRadius: 8, marginBottom: 10 }}>
                                  <p><strong>Arquivo selecionado:</strong> {filePlace.name}</p>
                                </S.RowContainer>
                              )
                            }
                            {
                              (filePlace) && (
                                <S.RowContainer style={{ justifyContent: 'space-evenly' }}>
                                  <Button variant='outlined' onClick={uploadFilePlace}>Anexar arquivo</Button>
                                  <Button variant='outlined'onClick={() => { setFilePlace(undefined) }}>Excluir</Button>
                                </S.RowContainer>
                              )
                            }
                          </S.ColumnContainer>
                        </S.RowContainer> */}
                      </form>
                    </S.FormContainer>
                  </S.ColumnContainer>
                  <S.ColumnContainer className='eventInformation' width={45}>
                    <h2>Editar Outras Informações</h2>
                    <h4 className='noMarginBottom'>Editar Política de Privacidade</h4>
                    <S.RowContainer style={{ marginTop: 15 }}>
                      <S.SelectFileBox>
                        <label htmlFor='file'>ESCOLHER ARQUIVO</label>
                        <input id='file' name='file' type='file' value={''} required accept='application/pdf' placeholder='Anexar Arquivo' onChange={(e) => { uploadPoliciesValidation(e.target.files?.[0]) }}/>
                      </S.SelectFileBox>
                    </S.RowContainer>
                    {
                      (filePolicies) && (
                        <S.RowContainer style={{ justifyContent: 'center', backgroundColor: '#3b81f744', borderRadius: 8, marginBottom: 10 }}>
                          <p><strong>Arquivo selecionado:</strong> {filePolicies.name}</p>
                        </S.RowContainer>
                      )
                    }
                    {
                      (filePolicies) && (
                        <S.RowContainer style={{ justifyContent: 'space-evenly' }}>
                          <Button variant='outlined'onClick={uploadFilePolicies}>Anexar arquivo</Button>
                          <Button variant='outlined'onClick={() => { setFilePolicies(undefined) }}>Excluir</Button>
                        </S.RowContainer>
                      )
                    }
                    <h4>Editar Outro Evento</h4>
                    <S.RowContainer style={{ marginTop: 15 }}>
                      <TextField style={{ textAlign: 'center' }} size='small' select variant='filled' id='event' name='event' value={editedEvent !== null ? editedEvent.label : ''} onChange={handleEditeOtherEvent} InputProps={{ disableUnderline: true }}>
                        <MenuItem value={editedEvent?.label} style={{ display: 'none' }} disabled>{editedEvent?.label}</MenuItem>
                        {
                          events.map((item, index) => (
                            <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                          ))
                        }
                      </TextField>
                    </S.RowContainer>
                    <h4 className='last'>Definir Outro Evento como Evento Atual</h4>
                    <S.RowContainer style={{ marginTop: 15 }}>
                      <TextField style={{ textAlign: 'center' }} size='small' select variant='filled' id='event' name='event' value={choosenEvent !== null ? choosenEvent.label : ''} onChange={handleSelectedEvent} InputProps={{ disableUnderline: true }}>
                        <MenuItem value={choosenEvent?.label} style={{ display: 'none' }} disabled>{choosenEvent?.label}</MenuItem>
                        {
                          events.map((item, index) => (
                            <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                          ))
                        }
                      </TextField>
                    </S.RowContainer>
                    <p style={{ textAlign: 'center', fontStyle: 'italic' }}>Ao selecionar um novo evento atual, os uploads e demais atividades passam a integrar este novo evento.</p>
                    <S.ButtonContainer>
                      <Button disabled={!newEventDefault} variant='outlined' className='button' onClick={handleNewEventDefault}>DEFINIR NOVO EVENTO PADRÃO</Button>
                    </S.ButtonContainer>
                  </S.ColumnContainer>
                </S.RowContainer>
              </>
            )
        }
      </S.ScreenContainer>
    </S.Container>
  )
}
