// Arquivo criado: 17/10/2023 às 22:55
import React from 'react'
import * as S from './styles'
import Button from '@mui/material/Button'
import { Navbar } from '../../components/Navbar'
import eventLogo from '../../assets/EIN_SELO_XXV_positivo.png'
import { useDispatch } from 'react-redux'
import { setLoginUser, setUser } from '../../redux/actions/user.action'
import { useNavigate } from 'react-router-dom'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import { type IUser } from '../../interfaces/IUser'
import * as CryptoJS from 'crypto-js'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import googleIcon from '../../assets/googleIcon.png'
import { googleConfig } from '../../config/google'

export const LoginEvaluator = (): JSX.Element => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [openLogin, setOpenLogin] = React.useState(false)
  const [login, setLogin] = React.useState(true)

  React.useEffect(() => {
    document.title = 'Submissão de trabalhos - Login Coordenadoria'
  }, [])

  const handleClose = async (): Promise<void> => {

    const newHashedPassword = CryptoJS.AES.encrypt(password, 'i642w0NXMCLt6MAbdxhjRNW7q77oqlbm').toString()
    const response = await backEnd('POST', 'evaluators/sign-in', { email, password: newHashedPassword })

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        modal: {
          title: 'Não foi possível fazer o login'
        },
        message: 'Verifique suas credenciais e tente novamente',
        type: 'error'
      }))

      setEmail('')
      setPassword('')
      return
    }

    const saveInfos = (): void => {
      const { token, ...user } = response.data

      dispatch(setLoginUser({
        isLogged: true,
        token
      }))

      dispatch(setUser(user))

      const accountType: IUser['accountType'] = response.data.accountType

      navigate(`/${accountType === 'user' ? '' : accountType === 'evaluator' ? 'avaliador' : accountType}`)

    }

    saveInfos()
  }

  const handleLoginModalClose = (): void => {
    setOpenLogin(false)
  }

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value)
    if (email !== '') {
      setLogin(false)
    }
  }

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value)
    if (password !== '') {
      setLogin(false)
    }
  }

  const handleModalOpen = (): void => {
    if (email === '') {
      setLogin(true)
      dispatch(setOpenGlobalMessageAction({
        message: 'O e-mail deve ser informado',
        type: 'error'
      }))

      return
    }

    if (!(/^[a-z0-9._+-]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/gi.test(email))) {
      setLogin(true)
      dispatch(setOpenGlobalMessageAction({
        message: 'E-mail inválido',
        type: 'error'
      }))

      return
    }

    if (password === '') {
      setLogin(true)
      dispatch(setOpenGlobalMessageAction({
        message: 'A senha deve ser informada',
        type: 'error'
      }))

      return
    }

    void handleClose()

  }

  const googleLogin = (): void => {
    localStorage.setItem('evaluator', '1')
    window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=${googleConfig.clientId}&redirect_uri=${googleConfig.redirectUri}&response_type=${googleConfig.responseType}&scope=${googleConfig.scope}`
  }

  return (
    <S.Container>
      <Navbar />
      <S.ScreenContainer>
        <S.RowContainer>
          <S.LogoContainer>
            <img src={eventLogo}/>
          </S.LogoContainer>
          <S.TitleContainer>
            <h1>Avaliação de Trabalhos</h1>
          </S.TitleContainer>
        </S.RowContainer>
        <S.FormContainer>
          <S.TextContainer>
            <Button className='einsteinButton' onClick={() => { setOpenLogin(true) }} variant='outlined'>Entrar com uma conta Einstein</Button>
            <p className='or'>ou</p>
            <Button onClick={googleLogin} className='googleButton' variant='outlined'><img src={googleIcon} width={20} style={{ marginRight: 10 }}/>Entrar com uma conta Google</Button>
          </S.TextContainer>
        </S.FormContainer>
        <Dialog open={openLogin} onClose={handleLoginModalClose}>
          <DialogTitle>Entre com suas credenciais Einstein:</DialogTitle>
          <DialogContent>
            <FormControl sx={{ m: 1, width: '95%' }} variant="outlined">
              <InputLabel sx={{ color: '#555' }} htmlFor="email">E-mail</InputLabel>
              <OutlinedInput id="email" label="E-mail" value={email} onChange={handleEmail} />
            </FormControl>
          </DialogContent>
          <DialogContent>
            <FormControl sx={{ m: 1, width: '95%' }} variant="outlined">
              <InputLabel sx={{ color: '#555' }} htmlFor="password">Senha</InputLabel>
              <OutlinedInput
                id="password"
                type={'password'}
                value={password}
                onChange={handlePassword}
                label="Senha"
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalOpen} disabled={login}>Acessar</Button>
          </DialogActions>
        </Dialog>
      </S.ScreenContainer>
    </S.Container>
  )
}
